import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { QButton, QCard, QCarousel } from 'src/shared/components'
import { CarouselRef } from 'src/shared/components/Carousel'

import categoriesMock from 'src/mocks/categories.json'

import { HeaderSection } from '../HeaderSection'

import { CategoriesStyle } from './styles'
import { numberSizes } from 'src/styles/theme/sizes'
import { useListCategories } from 'src/api/graphql'

interface ICategory {
  id: string
  name: string
  categories: string
  description: string
  imageLinks: string[]
}

interface IProps {
  names: string[]
}

export const Categories = (props: IProps) => {
  const [categories, setCategories] = useState<ICategory[]>([])
  const navigate = useNavigate()
  // const { data } = useListInventoryCommonsQuery(null)
  const query = useListCategories({ page: 1, perPage: 1000 }, { names: props.names })

  const carouselRef = useRef<CarouselRef>(null)
  const handleRedirect = (cat: string) => {
    navigate({
      pathname: '/products',
      search: `?categories=${cat}`,
    })
  }

  useEffect(() => {
    if (!query.loading) {
      if (query.data) {
        if (query.data.listCategories?.categories) {
          const dt: any[] = query.data.listCategories.categories ?? []
          const res: ICategory[] = []
          props.names.forEach((catName: string) => {
            const found = dt.find((cat: any) => cat.name == catName)
            if (found) {
              res.push({
                id: found._id,
                name: found.name,
                categories: found.name,
                description: `Categoría ${found.name}`,
                imageLinks: found.imageUrl ? [found.imageUrl] : [],
              })
            }
          })
          setCategories([...res])
        }
      }
    }
  }, [query.loading, query.data])

  return (
    <CategoriesStyle>
      <HeaderSection
        onChange={(event) => {
          if (event.direction === 'left') {
            carouselRef.current?.prev()
          } else {
            carouselRef.current?.next()
          }
        }}
        title="Categorías"
      />
      <br />
      <QCarousel
        ref={carouselRef}
        items={categories}
        options={{
          perPage: 1,
          breakpoints: {
            [numberSizes.lg]: {
              perPage: 3,
            },
            [numberSizes.md]: {
              perPage: 2,
            },
          },
        }}
        renderItem={(category) => (
          <div key={category.id} className="brand">
            <QCard style={{ padding: 0 }} hasShadow isRounded>
              <QCard.Image
                images={category.imageLinks.map((imgLink: string) => ({
                  url: imgLink,
                  alt: category.description,
                }))}
                objectFit="contain"
              />
              <QCard.Body
                style={{ padding: '20px' }}
                flex
                fDirection="column"
                justify="center"
                items="center"
                gap="10px"
              >
                {category ? <span className="category-name">{category.name}</span> : null}
                <QButton equip="secondary" onClick={() => handleRedirect(category.categories)}>
                  Ver productos
                </QButton>
              </QCard.Body>
            </QCard>
          </div>
        )}
      />
    </CategoriesStyle>
  )
}
