import sizes from 'src/styles/theme/sizes'
import styled from 'styled-components'

export const ContainerStyled = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
  @media screen and (min-width: ${sizes.lg}) {
    padding: 0;
  }
`
