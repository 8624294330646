import { SubBrandsStyled } from './styles'

const SubBrands = () => {
  return (
    <SubBrandsStyled>
      <h2 className="title">Principales clientes que construyen con EQUIP</h2>
      <div className="grid-images">
        {Array.from({ length: 20 }, (_, index) => index).map((idx: number) => (
          <img
            key={idx}
            className="image"
            src={`/images/brands/im${idx}.png`}
            height="67"
            width="134"
          />
        ))}
      </div>
    </SubBrandsStyled>
  )
}

export default SubBrands
