const constants = {
  specialCharacters: [
    ['A', 'Á|À|Ã|Â|Ä'],
    ['a', 'á|à|ã|â|ä'],
    ['E', 'É|È|Ê|Ë'],
    ['e', 'é|è|ê|ë'],
    ['I', 'Í|Ì|Î|Ï'],
    ['i', 'í|ì|î|ï'],
    ['O', 'Ó|Ò|Ô|Õ|Ö'],
    ['o', 'ó|ò|ô|õ|ö'],
    ['U', 'Ú|Ù|Û|Ü'],
    ['u', 'ú|ù|û|ü'],
    ['C', 'Ç'],
    ['c', 'ç'],
    ['N', 'Ñ'],
    ['n', 'ñ'],
  ],
  pagination: {
    perPage: 20,
  },
  userType: {
    buyer: 'Comprador',
    provider: 'Proveedor',
    hybrid: 'Ambos',
  },
  datetime: {
    format: 'DD/MM/YYYY',
    separator: '/',
  },
  dates: {
    requirement: {
      disabled: 1,
    },
  },
  requirementTypeUnit: [
    { prefix: 'UND', fullName: 'Unidad' },
    { prefix: 'MTS', fullName: 'Metros' },
    { prefix: 'KG', fullName: 'Kilos' },
    { prefix: 'TON', fullName: 'Toneladas' },
    { prefix: 'GL', fullName: 'Galones' },
    { prefix: 'M2', fullName: 'Metros cuadrados' },
    { prefix: 'M3', fullName: 'Metros cúbicos' },
    { prefix: 'OTROS', fullName: 'Otros' },
  ],
}

export default constants
