import { FC } from 'react'

export const WspIcon: FC<React.SVGProps<SVGSVGElement>> = ({
  viewBox,
  className,
  width,
  height,
  ...rest
}) => {
  return (
    <svg
      viewBox={viewBox ?? '0 0 35 37'}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...rest}
    >
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.5683 35.851H17.5755C27.1828 35.8471 34.9967 28.0314 35.0005 18.4261C35.0024 13.7702 33.1915 9.39223 29.9017 6.09869C26.6118 2.80497 22.2367 0.990293 17.5753 0.988281C7.97074 0.988281 0.153822 8.80478 0.149999 18.4123C0.148692 21.4834 0.95108 24.481 2.47597 27.1237L0.00390625 36.1532L9.24123 33.7301C11.7864 35.1183 14.6519 35.85 17.5683 35.851ZM25.756 22.1776C26.0597 22.3244 26.2648 22.4235 26.3523 22.5696C26.4611 22.7512 26.4611 23.6229 26.0985 24.6401C25.7355 25.6571 23.9961 26.5853 23.1595 26.7103C22.4095 26.8225 21.4602 26.8692 20.4172 26.5378C19.7848 26.3371 18.9739 26.0692 17.9351 25.6206C13.8534 23.8581 11.095 19.9021 10.5736 19.1544C10.5371 19.102 10.5116 19.0653 10.4973 19.0463L10.4938 19.0416C10.2634 18.7342 8.71948 16.6743 8.71948 14.5423C8.71948 12.5368 9.70464 11.4856 10.1581 11.0017C10.1892 10.9685 10.2177 10.9381 10.2433 10.9101C10.6424 10.4742 11.1141 10.3652 11.4044 10.3652C11.6946 10.3652 11.9852 10.3679 12.2389 10.3807C12.2702 10.3822 12.3027 10.382 12.3364 10.3818C12.5901 10.3804 12.9064 10.3785 13.2185 11.1281C13.3385 11.4165 13.5142 11.8442 13.6995 12.2952C14.0741 13.2073 14.488 14.2151 14.5609 14.3609C14.6697 14.5789 14.7423 14.833 14.5972 15.1237C14.5754 15.1673 14.5552 15.2085 14.536 15.2478C14.427 15.4703 14.3468 15.634 14.1618 15.85C14.089 15.9349 14.0139 16.0265 13.9387 16.1181C13.7889 16.3005 13.6391 16.4829 13.5087 16.6128C13.2907 16.8299 13.0638 17.0655 13.3178 17.5014C13.5718 17.9373 14.4456 19.363 15.7399 20.5176C17.1313 21.7586 18.3406 22.2831 18.9535 22.549C19.0732 22.6009 19.1702 22.643 19.2413 22.6786C19.6765 22.8966 19.9305 22.8601 20.1845 22.5696C20.4385 22.2791 21.2729 21.2982 21.5631 20.8624C21.8533 20.4267 22.1437 20.4993 22.5427 20.6445C22.9419 20.7899 25.0825 21.8431 25.5179 22.061C25.6029 22.1036 25.6824 22.142 25.756 22.1776Z"
          fill="#20BF55"
        />
      </>
    </svg>
  )
}
