declare global {
  interface Window {
    dataLayer: any[]
  }
}

import { Product } from 'src/interfaces/ecommerce'

export const useViewItem = (product: Product) => {
  window.dataLayer.push({
    event: 'view_item',
    'ecommerce.items': JSON.stringify([
      {
        skuEquip: product.skuEquip,
        name: product.name,
        brand: product.brand,
        categories: product.categories?.join(',') ?? '',
        qty: product.qty ?? 1,
      },
    ]),
  })
}

export const useAddToCart = (product: Product) => {
  window.dataLayer.push({
    event: 'add_to_cart',
    'ecommerce.items': JSON.stringify([
      {
        skuEquip: product.skuEquip,
        name: product.name,
        brand: product.brand,
        categories: product.categories?.join(',') ?? '',
        qty: product.qty ?? 1,
      },
    ]),
  })
}

export const useRemoveFromCart = (product: Product) => {
  window.dataLayer.push({
    event: 'remove_from_cart',
    'ecommerce.items': JSON.stringify([
      {
        skuEquip: product.skuEquip,
        name: product.name,
        brand: product.brand,
        categories: product.categories?.join(',') ?? '',
        qty: product.qty ?? 1,
      },
    ]),
  })
}

export const useViewCart = () => {
  window.dataLayer.push({
    event: 'view_cart',
    'ecommerce.transaction': 'normal',
  })
}

export const useSelectProduct = (product: Product) => {
  window.dataLayer.push({
    event: 'select_item',
    'ecommerce.items': JSON.stringify([
      {
        skuEquip: product.skuEquip,
        name: product.name,
        brand: product.brand,
        categories: product.categories?.join(',') ?? '',
        qty: product.qty ?? 1,
      },
    ]),
  })
}

export const inventory2product = (inventory: any) => {
  const pr: Product = {
    id: inventory?.key ? inventory?.key : inventory?._id ?? '',
    name: inventory?.name ?? '',
    description: inventory?.description ?? '',
    brand: inventory?.brand ?? '',
    categories: inventory?.categories ?? [],
    skuEquip: inventory?.skuEquip ?? '',
    imageUrl: inventory?.imageUrl ?? '',
    techsheetUrl: inventory?.techsheetUrl ?? '',
    currency: inventory?.currency ?? '',
    price: inventory?.price ?? 0,
    qty: inventory?.qty ?? 1,
  }
  return pr
}
