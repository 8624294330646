import sizes from 'src/styles/theme/sizes'
import styled from 'styled-components'

const fontFamily = 'Poppins'
export const SubFooterStyled = styled.div`
  padding: 20px;
  background-image: url('/images/banner-sub-footer-mobile.png');
  background-size: cover;
  background-position: center;
  .container {
    display: grid;
    row-gap: 20px;
    align-items: center;
    max-width: 1200px;
    margin: auto;
  }
  @media screen and (min-width: ${sizes.md}) {
    padding: 65px 40px;
  }
  @media screen and (min-width: ${sizes.lg}) {
    background-image: url('/images/banner-sub-footer.png');
    row-gap: 25px;
    grid-template-columns: 226px auto;
    grid-template-rows: repeat(2, auto);
  }
  @media screen and (min-width: ${sizes.xl}) {
    grid-template-columns: 415px auto;
  }

  .header {
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 20px;
    @media screen and (min-width: ${sizes.lg}) {
      row-gap: 25px;
      align-items: unset;
    }

    .title {
      font-weight: 700;
      font-size: 24px;
      font-family: ${fontFamily};
      color: white;
      @media screen and (min-width: ${sizes.md}) {
        font-size: 36px;
      }
    }
  }
  .figures {
    display: grid;
    row-gap: 10px;
    justify-content: center;
    @media screen and (min-width: ${sizes.sm}) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: unset;
      gap: 20px;
    }
    @media screen and (min-width: ${sizes.lg}) {
      grid-column: 2/3;
      grid-row: 1/3;
    }
  }

  .col {
    display: flex;
    align-items: center;
    flex-direction: column;

    .title {
      font-weight: 900;
      font-size: 40px;
      color: white;
      font-family: ${fontFamily};
      @media screen and (min-width: ${sizes.md}) {
        font-size: 64px;
      }
    }

    .subtitle {
      font-weight: 700;
      font-size: 14px;
      color: white;
      text-align: center;
      font-family: ${fontFamily};
      @media screen and (min-width: ${sizes.md}) {
        font-size: 16px;
      }
    }
  }
  .button {
    order: 2;
    width: 185px;
    margin: auto;
    @media screen and (min-width: ${sizes.lg}) {
      margin: unset;
    }
  }
`
