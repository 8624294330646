import { Button, Card } from 'antd'
import styled from 'styled-components'

export const CardStyled = styled(Card)`
  border-radius: 0;
  width: 100vw;
  .ant-card-body {
    padding: 40px 20px;
    height: calc(100vh - 90px);
  }
  .ant-result-icon {
    margin-bottom: 14px;
  }
  .ant-result-title {
    font-weight: 700;
    font-size: 34px;
  }
  @media (min-width: 640px) {
    border-radius: 16px;
    min-width: 550px;
    max-width: 550px;

    .ant-card-body {
      padding: 25px 40px 40px;
      height: 100%;
    }
  }
`
export const CenterStyled = styled.div`
  height: 100vh;
  display: flex;
  align-items: flex-end;
  @media (min-width: 640px) {
    justify-content: center;
    align-items: center;
  }
`
export const ButtonStyled = styled(Button)`
  width: -webkit-fill-available;
  background-color: #ffbe2e;
  color: #111d5e;
  border-color: #ffbe2e;
  font-weight: 800;
  font-size: 20px;
  height: 60px;
  border-radius: 8px;
  margin-top: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
  &.ant-btn-primary:not(:disabled):hover {
    background-color: #ffbe2e;
    border-color: #ffbe2e;
    color: #111d5e;
  }
`
