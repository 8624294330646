import { useQuery } from '@apollo/client'

import { Platforms } from 'src/interfaces/types/app'

import { queryListInvs, queryObtainStructure } from '../queries/listInventories'

export const useListInventories = (paginated: any, filter?: any) => {
  return useQuery(queryListInvs, {
    context: { clientName: Platforms.business },
    variables: { paginated, filter },
  })
}

export const useObtainStructure = (input: any) => {
  return useQuery(queryObtainStructure, {
    context: { clientName: Platforms.business },
    variables: { input },
  })
}
