import { FC, useState } from 'react'

import { ImageStyle } from './styles'

export interface CardImage {
  url: string
  alt: string
}

export interface ImageProps {
  isGrid?: boolean
  images: CardImage[]
  objectFit?: React.CSSProperties['objectFit']
  style?: React.CSSProperties
}

const Image: React.FC<ImageProps> = ({ isGrid = false, images, objectFit, style }) => {
  const imagesToRender = isGrid ? images.filter((_, i) => i < 3) : [images[0] ?? {}] // Solo tomar 3 imágenes o tomar el primero de ellos
  const [loadedList, setLoadedList] = useState<boolean | boolean[]>(false)

  const handleLoaded = (index: number) => {
    if (Array.isArray(loadedList)) {
      setLoadedList([...loadedList.map((_, idx) => idx === index)])
    }
    setLoadedList(true)
  }

  const getLoadedClassname = (index: number): string => {
    if ((Array.isArray(loadedList) && Boolean(loadedList[index])) || Boolean(loadedList)) {
      return 'loaded'
    }

    return ''
  }

  const handleError = ({ currentTarget }: React.SyntheticEvent<HTMLImageElement, Event>) => {
    currentTarget.onerror = null
    currentTarget.src = '/images/no-image.png'
    currentTarget.className = 'fallback'
  }

  return (
    <ImageStyle isGrid={isGrid} style={style} objectFit={objectFit}>
      {imagesToRender?.map((image, index) => (
        <Img
          key={`image-${index}`}
          src={image?.url}
          alt={image?.alt}
          onError={handleError}
          onLoad={() => handleLoaded(index)}
          className={getLoadedClassname(index)}
        />
      ))}
    </ImageStyle>
  )
}

export default Image

export const Img: FC<
  React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = ({ src, alt, ...rest }) => {
  const handleError = ({ currentTarget }: React.SyntheticEvent<HTMLImageElement, Event>) => {
    currentTarget.onerror = null
    currentTarget.src = '/images/no-image.png'
    currentTarget.className = 'fallback'
  }

  return <img src={src} alt={alt} onError={handleError} {...rest} />
}
