import styled from 'styled-components'

import colors from 'src/styles/theme/colors'
import sizes from 'src/styles/theme/sizes'

const fontFamily = 'Poppins'
export const HeaderSectionStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: center;
  .HeaderSection__title {
    font-weight: 700;
    font-size: 24px;
    font-family: ${fontFamily};
    text-align: center;
    color: ${colors.black};
    @media screen and (min-width: ${sizes.md}) {
      font-size: 36px;
    }
  }
  .arrow {
    display: none;
  }
  @media screen and (min-width: ${sizes.md}) {
    .arrow {
      display: initial;
    }
  }
`
