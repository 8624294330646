import { Checkbox, Tree } from 'antd'
import styled from 'styled-components'

export const FiltersStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  h4 {
    font-weight: bold;
    margin: 0 0 10px;
  }
`

export const CheckboxGroupStyled = styled(Checkbox.Group)`
  flex-direction: column;
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin: 0;
  }
`
export const TreeStyled = styled(Tree)`
  &.ant-tree {
    background: unset;
  }
`

interface FilterGroupProps {
  open: boolean
  height: number
}

export const FiltersGroupStyled = styled.div<FilterGroupProps>`
  max-height: ${({ height }) => `${height}px`};
  overflow-y: ${({ open }) => (open ? 'auto' : 'hidden')};
`
