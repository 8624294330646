import { Checkbox, Form, Button, Row, Col, Spin, Typography } from 'antd'
import { useFormik } from 'formik'
import {
  BillingInputsStyled,
  ButtonStyled,
  FormTitleStyled,
  HighlightedTextStyled,
  InputStyled,
  PartLeftStyled,
  PartRightStyled,
  RequirementModalStyled,
  SloganStyled,
  TitleStyled,
} from './styles'
import { requirementSchema } from './validationSchema'
import { useEffect, useRef, useState } from 'react'
import { createRequirement, obtainLegal, registerBuyer } from 'src/api/graphql'
import { Platforms } from 'src/interfaces/types/app'
import { QNotify } from '..'
import { useNavigate } from 'react-router-dom'

interface RequirementFormProps {
  onSuccess?: () => void
  open: boolean
  handleClose: () => void
}

export const RequirementFormModal = ({
  onSuccess,
  open,
  handleClose: onClose,
}: RequirementFormProps) => {
  const [reqLoading, setReqLoading] = useState(false)
  const [register] = registerBuyer()
  const navigate = useNavigate()
  const [addRequirement] = createRequirement()
  const { handleSubmit, handleChange, values, setFieldValue, resetForm, errors, touched, isValid } =
    useFormik({
      initialValues: {
        fullname: '',
        cellphone: '',
        ruc: '',
        email: '',
        address: '',
        company: '',
        terms: false,
      },
      validationSchema: requirementSchema,
      validateOnMount: true,
      onSubmit: async (values, { resetForm }) => {
        const struct = {
          name: values.fullname,
          lastname: '', // values.fullname,
          ruc: values.ruc,
          company: values.company,
          address: values.address,
          categories: [],
          email: values.email,
          cellphone: values.cellphone,
          documentType: '',
          documentNumber: '',
          isBuyer: true,
          isProvider: false,
          origin: 'store',
          status: 'registered',
          flow: 'anonymous',
          isTerms: values.terms,
          isMkt: true,
          password: '',
        }
        setReqLoading(true)
        await register({
          context: { clientName: Platforms.accounts },
          variables: { input: struct },
        })
          .then((res: any) => {
            if (!res?.data?.register) {
              setReqLoading(false)
              QNotify({
                type: 'error',
                message: '¡Ocurrió un error!',
                description: 'Intente nuevamente en unos minutos',
              })
            }
            const dt = res.data.register
            const account = dt.account
            const accountId = account._id

            if (dt.code == 201) {
              QNotify({
                message: 'Comprador encontrado',
                type: 'success',
              })
            } else {
              QNotify({
                message: 'Comprador creado correctamente',
                description: 'Te enviamos un correo para que confirmes tu cuenta',
                type: 'success',
              })
            }

            addRequirement({
              context: { clientName: Platforms.business },
              variables: {
                input: {
                  accountId,
                  origin: 'store',
                  flow: 'fast',
                  actionBy: `${values.fullname} (${values.company})`,
                },
              },
            })
              .then((data) => {
                onSuccess?.()
                const requirementId = data.data.addRequirement._id
                navigate(`/requirements/generate/${requirementId}?accountId=${accountId}`)
                setReqLoading(false)
                resetForm({})
              })
              .catch((e) => {
                setReqLoading(false)
                QNotify({
                  message: e?.message,
                  type: 'error',
                })
              })
          })
          .catch((err: any) => {
            setReqLoading(false)
            QNotify({
              type: 'warning',
              message: err.message,
            })
          })
      },
    })

  const [loadInfoLegal, infoLegal, loading] = obtainLegal({})

  const handleClose = () => {
    resetForm({})
    onClose()
  }

  useEffect(() => {
    if (values.ruc.length === 11) {
      loadInfoLegal({
        context: { clientName: Platforms.business },
        fetchPolicy: 'network-only',
        variables: {
          input: {
            ruc: values.ruc,
          },
        },
      }).then((res: any) => {
        const business = res?.data?.getLegal
        if (business) {
          setFieldValue('company', business.businessName)
          setFieldValue('address', business.businessLocation?.address || 'Sin Dirección')
        }
      })
    }
  }, [values.ruc])

  return (
    <RequirementModalStyled footer={null} open={open} onCancel={handleClose}>
      <Spin spinning={reqLoading}>
        <Row justify="center">
          <Col sm={12}>
            <PartLeftStyled>
              <TitleStyled>
                Compra y vende materiales de construcción en pocos minutos{' '}
                <HighlightedTextStyled>ahorrando tiempo y dinero.</HighlightedTextStyled>
              </TitleStyled>
              <SloganStyled>
                Cotizamos tu pedido entre cientos de proveedores y te brindamos el mejor precio.
              </SloganStyled>
            </PartLeftStyled>
          </Col>
          <Col sm={12}>
            <PartRightStyled>
              <Form onFinish={handleSubmit}>
                <FormTitleStyled>Empieza ahora enviando tu requerimiento</FormTitleStyled>
                <Form.Item style={{ marginBottom: '1.25rem' }}>
                  <InputStyled
                    name="fullname"
                    onChange={handleChange}
                    value={values.fullname}
                    placeholder="Nombre y Apellido"
                  />
                </Form.Item>
                <Form.Item style={{ marginBottom: '1.25rem' }}>
                  <InputStyled
                    name="cellphone"
                    placeholder="Telf/Cel"
                    onChange={handleChange}
                    value={values.cellphone}
                  />
                </Form.Item>
                <Form.Item style={{ marginBottom: '1.25rem' }}>
                  <BillingInputsStyled>
                    <InputStyled
                      name="ruc"
                      onChange={handleChange}
                      value={values.ruc}
                      placeholder="Ruc"
                    />
                    <InputStyled
                      name="company"
                      onChange={handleChange}
                      value={loading ? 'Buscando...' : values.company}
                      placeholder="Company"
                    />
                  </BillingInputsStyled>
                </Form.Item>
                <Form.Item style={{ marginBottom: '1.25rem' }}>
                  <InputStyled
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                    placeholder="Correo electrónico"
                  />
                </Form.Item>
                <Form.Item style={{ marginBottom: '1.25rem' }}>
                  <Checkbox name="terms" onChange={handleChange} checked={values.terms}>
                    Acepto términos, condiciones y las políticas de privacidad.
                  </Checkbox>
                  {errors.terms && <Typography.Text type="danger">{errors.terms}</Typography.Text>}
                </Form.Item>
                <ButtonStyled htmlType="submit" size="large" block disabled={!isValid}>
                  Quiero cotizar
                </ButtonStyled>
              </Form>
            </PartRightStyled>
          </Col>
        </Row>
      </Spin>
    </RequirementModalStyled>
  )
}
