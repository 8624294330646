import { useEffect, useRef } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { PlusCircleFilled } from '@ant-design/icons'

import { Product } from 'src/interfaces/ecommerce'

import { useCart, useProducts } from 'src/shared/hooks'

import { QButton, QCard, QCarousel } from 'src/shared/components'
import { CarouselRef } from 'src/shared/components/Carousel'

import { HeaderSection } from '../HeaderSection'

import { ProductsStyled } from './styles'

interface IProps {
  names: string[]
}

export const Products = (props: IProps) => {
  const carouselRef = useRef<CarouselRef>(null)

  const { products } = useProducts({
    pagination: { page: 1, perPage: 40 },
    filters: { names: props.names },
  })

  const { addProductToCart } = useCart()

  return (
    <ProductsStyled>
      <HeaderSection
        onChange={(event) => {
          if (event.direction === 'left') {
            carouselRef.current?.prev()
          } else {
            carouselRef.current?.next()
          }
        }}
        title="Productos"
        actions={
          <NavLink to="/products">
            <QButton equip="primary" type="default" isHovered>
              Ver todos
            </QButton>
          </NavLink>
        }
      />
      <br />
      <QCarousel
        ref={carouselRef}
        items={products}
        renderItem={(product: Product) => (
          <div key={product.id} className="product">
            <QCard key={product.id} style={{ padding: 0 }} isRounded isBordered hasShadow>
              <Link to={`/products/${product.skuEquip}`}>
                <QCard.Image images={[{ url: product.imageUrl, alt: product.name }]} />
              </Link>
              <QCard.Body style={{ padding: '10px', borderTop: '1px solid #DEE2E6' }}>
                <Link to={`/products/${product.skuEquip}`}>
                  <p className="product-name">{`${product.name}`}</p>
                </Link>
                <QButton
                  block
                  type="link"
                  size="large"
                  style={{
                    fontSize: 16,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  icon={<PlusCircleFilled className="icon" />}
                  onClick={() => addProductToCart(product)}
                >
                  Agregar
                </QButton>
              </QCard.Body>
            </QCard>
          </div>
        )}
      />
    </ProductsStyled>
  )
}
