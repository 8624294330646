import { gql } from '@apollo/client'

export const queryObtainAccount = gql`
  query obtainAccount($input: InputID) {
    obtainAccount(input: $input) {
      _id
      code
      avatar
      name
      lastname
      ruc
      company
      email
      cellphone
      address
      categories
      documentType
      documentNumber
      isBuyer
      isProvider
      isTerms
      isMkt
      status
      origin
      role
      accountVid
      processing
      companyVid
      createdAt
      updatedAt
    }
  }
`
