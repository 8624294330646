import { gql } from '@apollo/client'

export const queryListDepartments = gql`
  query listDepartaments {
    listDepartments {
      _id
      name
      code
    }
  }
`
