import styled from 'styled-components'

const borderRadius = '8px'
export const BannerStyled = styled.div`
  border-radius: ${borderRadius};
  position: relative;
  height: 100%;
  margin-bottom: 40px;
  .banner-pic {
    max-height: 270px;
    min-height: 200px;
    width: 100%;
    object-fit: cover;
    z-index: 10;
    box-shadow: 0 10px 30px 0 #195aff26;
  }
  .profile-pic {
    width: 132px;
    height: 132px;
    position: absolute;
    z-index: 20;
    left: 50%;
    bottom: 20px;
    transform: translate(-50%, 0);
    box-shadow: 0 10px 30px 0 #195aff26;
    background-color: white;
    border-radius: ${borderRadius};
    padding: 10px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  img {
    border-radius: ${borderRadius};
  }
`
