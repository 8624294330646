class Colors {
  background: string = '#ffffff'
  blue: string = '#111D5E'
  orange: string = '#ff9d0b'
  footerBackground = '#d9d9d9'
  containerBackground = '#ededed'
  black = '#000000'
  white = '#fff'
  logoBlue = '#030852'
  blueHover = '#1890ff'
  borderGray = '#F0F0F0'
  primary = '#FF9D0B'
  primaryGradient = 'linear-gradient(106.08deg, #FF9D0B 0.52%, #FF7A00 100%)'
  secondary = '#030852'
  default = '#D9D9D9'
}

export default new Colors()
