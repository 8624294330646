import { FC } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { Badge, Button, Drawer } from 'antd'
import { ShoppingCartOutlined, MenuOutlined } from '@ant-design/icons'

import { useViewCart } from 'src/services/gtm'

import { useCart, useDialog } from 'src/shared/hooks'
import { MenuMobile } from '../MenuMobile'

import { DrawerHeaderStyled, MenuIconStyled, NavIconsWrap } from './styles'

const NavIcons: FC<{ modalReq: ReturnType<typeof useDialog> }> = (props) => {
  const { products } = useCart()
  const { open, handleClose, handleOpen } = useDialog()

  const count = () => products.length

  return (
    <NavIconsWrap>
      <NavLink to={'/cart'} onClick={useViewCart}>
        <Badge count={count()}>
          <ShoppingCartOutlined style={{ fontSize: 26 }} />
        </Badge>
      </NavLink>

      <MenuIconStyled>
        <MenuOutlined onClick={handleOpen} />
      </MenuIconStyled>

      <Drawer
        title={
          <DrawerHeaderStyled>
            <Link className="logo-desktop" to="/">
              <img className="logo" src="/logos/logo.svg" alt="Logo de Equip" />
            </Link>
            <Button onClick={handleClose}>Cerrar</Button>
          </DrawerHeaderStyled>
        }
        placement="left"
        closable={false}
        onClose={handleClose}
        open={open}
        key="left"
      >
        <MenuMobile modalReq={props.modalReq} handleClose={handleClose} />
      </Drawer>
    </NavIconsWrap>
  )
}

export default NavIcons
