import { IAccount } from 'src/shared/helpers/storage'

export const accountDataToSotarage = (data: any, entity: string): IAccount => {
  const { _id: id } = data.obtainAccount ?? {}

  return {
    id,
    entity,
  }
}
