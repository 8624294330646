import { LocalStorageEntities } from 'src/interfaces/types/app'

const ACCOUNT_STORAGE_KEY = 'account'

interface ILocalStorage<T> {
  data: T | T[]
  entity: LocalStorageEntities
}

export interface IAccount {
  id: string
  entity: string
}

class Storage {
  persistDataLocalStorage = <T>({ entity, data }: ILocalStorage<T>): void => {
    const body = JSON.stringify(data)
    localStorage.setItem(entity, body)
  }

  getDataLocalStorage = <T>(entity: LocalStorageEntities): T => {
    const data = localStorage.getItem(entity)
    return JSON.parse(data as string)
  }

  getAccountId = () => {
    const data = localStorage.getItem('account')
    const obj = JSON.parse(data as string)
    return obj?.id
  }

  clean = () => localStorage.clear()
}

export default new Storage()
