import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import config from 'src/config/app'

export const commonsApi = createApi({
  reducerPath: 'commons',
  baseQuery: fetchBaseQuery({ baseUrl: config.platform.business }),
  endpoints: (builder) => ({
    listInventoryCommons: builder.query<any, any>({
      query: () => `/api/v1/inventory/list-commons-filters/all`,
      transformResponse: (response: { data: any }) => response.data,
    }),
  }),
})

export const { useListInventoryCommonsQuery } = commonsApi
