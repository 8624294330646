import { FC } from 'react'
import { Popover, Menu, Button, Avatar, Row, Col, Divider } from 'antd'
import { UserOutlined, LogoutOutlined } from '@ant-design/icons'

import { QButton } from 'src/shared/components'

import { useAuth } from 'src/auth/authProvider'
import config from 'src/config/app'

const loggedMenu = (user: any) => {
  const logout = () => {
    window.location.href = `${config.platform.buyer}/logout`
  }

  return (
    <>
      <Row align="middle">
        <Col span={4}>
          <Avatar size={35}>{user?.name?.at(0)}</Avatar>
        </Col>
        <Col span={20} style={{ display: 'grid' }}>
          <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
            {user?.name} {user?.lastname}
          </span>
          <span style={{ fontSize: '12px' }}>{user?.email}</span>
        </Col>
      </Row>

      <Divider type="horizontal" style={{ marginTop: '10px', marginBottom: '0px' }} />

      <Menu
        style={{ width: '270px' }}
        items={[
          {
            key: 'buyer',
            label: 'Ir a panel',
            onClick: () => {
              window.location.href = config.platform.buyer ?? ''
            },
          },
          { key: 'logout', label: 'Cerrar Sesión', icon: <LogoutOutlined />, onClick: logout },
        ]}
      />
    </>
  )
}

const notLoggedMenu = () => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 10,
          minWidth: 250,
        }}
      >
        <h2 style={{ margin: 0 }}>Cuenta</h2>
        <QButton block icon={<UserOutlined />} href={config.platform.auth}>
          Iniciar Sesión
        </QButton>
        <QButton equip="secondary" href="/register" block>
          Registrarse
        </QButton>
      </div>
    </>
  )
}

export const UserPopover: FC = () => {
  const { user } = useAuth()
  return (
    <Popover
      placement="bottomRight"
      content={user?._id ? loggedMenu(user) : notLoggedMenu()}
      trigger={['click']}
    >
      {user?.name ? (
        <Avatar style={{ cursor: 'pointer' }}>{user?.name?.at(0)}</Avatar>
      ) : (
        <Button type="text" size="large" icon={<UserOutlined style={{ fontSize: 20 }} />} />
      )}
    </Popover>
  )
}
