import { useLazyQuery, useQuery } from '@apollo/client'

import { Platforms } from 'src/interfaces/types/app'

import {
  queryListCategories,
  queryListFilters,
  queryListSelectorCategories,
  queryObtainLegal,
} from '../queries/commons'

export const obtainLegal = (input: any) => {
  let response: any = {}
  const [loadInfoLegal, { data, loading }] = useLazyQuery(queryObtainLegal, {
    context: { clientName: Platforms.business },
    fetchPolicy: 'no-cache',
    variables: input,
  })
  if (data) {
    const { getLegal } = data
    response = getLegal
  }
  return [loadInfoLegal, response, loading]
}

export const listFilters = (paginated: any, filter: any) => {
  const response = useQuery(queryListFilters, {
    context: { clientName: Platforms.business },
    variables: { paginated, filter },
    fetchPolicy: 'cache-and-network',
  })
  return response
}

export const listCategories = (paginated: any, filter: any) => {
  const response = useQuery(queryListCategories, {
    context: { clientName: Platforms.business },
    variables: { paginated, filter },
    fetchPolicy: 'cache-and-network',
  })
  return response
}

export const listSelectorCategories = () => {
  const response = useQuery(queryListSelectorCategories, {
    context: { clientName: Platforms.business },
  })
  return response
}
