import { FC, useState } from 'react'
import { Result, Typography, Button, Form, Input, Checkbox } from 'antd'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { Platforms } from 'src/interfaces/types/app'

import { useComplete } from 'src/api/graphql/resolvers/accounts'

import { QNotify, QLoading } from 'src/shared/components'

import config from 'src/config/app'

import { CardStyled, CenterStyled, FormItemStyle } from './styles'

const styles: any = {
  titleStyle: {
    color: '#111D5E',
    marginBottom: 0,
  },
  subtitleStyle: {
    color: '#111D5E',
    marginBottom: 0,
    fontSize: 18,
  },
  buttonStyle: {
    width: '-webkit-fill-available',
    backgroundColor: '#FFBE2E',
    color: '#111D5E',
    borderColor: '#FFBE2E',
    fontWeight: 800,
    fontSize: 'clamp(1rem, 5vw, 20px)',
    height: 60,
    borderRadius: 8,
    margin: '30px 0',
    textTransform: 'uppercase',
    letterSpacing: '2px',
  },
  dFlexBtw: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    gridGap: '0 50px',
    marginBottom: '5px',
  },
  mb40: {
    marginBottom: 40,
  },
}

const { Title } = Typography

const initialValues = {
  password: '',
  rePassword: '',
  mkt: false,
}

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!?/@#\$%\^&\*()\-\+\:\;])(?=.{8,})/,
      'La contraseña debe tener al menos 8 caracteres y contener una letra mayúscula, una minúscula, un número y un símbolo - / : ; ()& @?! % *'
    )
    .required('Este campo es requerido'),
  rePassword: yup.string().oneOf([yup.ref('password'), null], 'Las contraseñas deben coincidir'),
})

export const CompleteRegBuyer: FC<{ hash: string }> = ({ hash }) => {
  const [finish, setFinish] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => handleFinish(values),
  })
  const { errors } = formik

  const [complete, { loading }] = useComplete()

  const handleFinish = (values: any) => {
    const data = {
      accountId: hash,
      password: values.password,
      rePassword: values.rePassword,
    }
    complete({ context: { clientName: Platforms.accounts }, variables: { input: data } })
      .then(() => {
        setFinish(true)
      })
      .catch((err: any) => {
        QNotify({
          type: 'error',
          message: '¡Ocurrió un error!',
          description: err.graphQLErrors[0]?.message,
        })
      })
  }

  const formHeaderCard = (
    <div style={styles.mb40}>
      <div style={styles.dFlexBtw}>
        <div>
          <Title level={2} style={styles.titleStyle}>
            Completar Registro
          </Title>
        </div>

        <div>
          <img height={7} width={115} src="/images/rectline.png" alt="line" />
        </div>
      </div>
      <p style={styles.subtitleStyle}>Coloque su contraseña para finalizar el registro</p>
    </div>
  )

  const formCard = (
    <QLoading loading={loading}>
      <CardStyled>
        {formHeaderCard}

        <Form layout="vertical" onFinish={formik.handleSubmit}>
          <FormItemStyle
            name="password"
            validateStatus={errors.password && 'error'}
            help={errors.password}
          >
            <Input.Password
              name="password"
              placeholder="Contraseña"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </FormItemStyle>

          <FormItemStyle
            name="rePassword"
            validateStatus={errors.rePassword && 'error'}
            help={errors.rePassword}
          >
            <Input.Password
              name="rePassword"
              placeholder="Confirmar Contraseña"
              value={formik.values.rePassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </FormItemStyle>
          <Checkbox name="mkt" onChange={formik.handleChange} checked={formik.values.mkt}>
            Acepto el uso de mis datos personales para fines publicitarios
          </Checkbox>

          <Button type="primary" size="large" htmlType="submit" style={styles.buttonStyle}>
            Completar registro
          </Button>
        </Form>
      </CardStyled>
    </QLoading>
  )

  const resultCard = (
    <CardStyled>
      <Result
        title={
          <Title level={2} style={styles.titleStyle}>
            Registro completado
          </Title>
        }
        status="success"
        extra={
          <>
            <p style={styles.subtitleStyle}>
              Bienvenido a Equip, haz click en el siguiente botón para iniciar sesión
            </p>
            <a href={config.platform.auth}>
              <Button type="primary" size="large" style={styles.buttonStyle}>
                Iniciar Sesión
              </Button>
            </a>
          </>
        }
      />
    </CardStyled>
  )

  return (
    <CenterStyled>
      <div>{!finish ? formCard : resultCard}</div>
    </CenterStyled>
  )
}
