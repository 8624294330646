import { FilterKeyEnum, useFilters } from 'src/shared/hooks'
import { PanelCategory } from '../hooks/useListFilters'
import Format from 'src/shared/helpers/format'
import { dividerArr } from 'src/shared/utils/arrays'

interface CategoriesPanelContentProps {
  categories: PanelCategory[]
  handleClose?: () => void
}

export const CategoriesPanelContent = ({
  categories,
  handleClose,
}: CategoriesPanelContentProps) => {
  const { applyNavigateFilter } = useFilters()
  const categoriesDivided = dividerArr(categories, 3)

  return (
    <>
      {categoriesDivided.map((categories, index) => (
        <li className="panel-column" key={index}>
          {categories.map((catItem, index) => (
            <div className="section-wrapper" key={`${catItem.id}-${index}`}>
              <span
                className="title-category item item-label"
                onClick={() => {
                  handleClose?.()
                  applyNavigateFilter('/products', [
                    [FilterKeyEnum.categories, Format.capitalizeFirstLetter(catItem.name)],
                  ])
                }}
              >
                {catItem.name}
              </span>
              <ul>
                {catItem.subcategories?.map((cat: any, index: number) => {
                  return (
                    <li
                      className="subitem subitem-label"
                      key={index}
                      onClick={() => {
                        handleClose?.()
                        applyNavigateFilter('/products', [
                          [FilterKeyEnum.categories, Format.capitalizeFirstLetter(cat.name)],
                        ])
                      }}
                    >
                      {cat.name}
                    </li>
                  )
                })}
              </ul>
            </div>
          ))}
        </li>
      ))}
    </>
  )
}
