import { Button, Checkbox, Col, Row } from 'antd'
import { ProviderFiltersStyled } from './styles'
import { CheckboxGroupProps } from 'antd/es/checkbox'

interface ProviderFiltersProps {
  onChange: (value: any) => void
  onCleanFilters: (value: any) => void
  categories: any[]
  values: string[]
}

export const ProviderFilters: React.FC<ProviderFiltersProps> = ({
  onChange,
  categories,
  onCleanFilters,
  values,
}) => {
  const handleChange: CheckboxGroupProps['onChange'] = (checkedValues) => {
    onChange(checkedValues)
  }
  return (
    <ProviderFiltersStyled>
      <div className="provider-filters-header">
        <h1>Categorías</h1>
        <Button onClick={onCleanFilters} type="text" style={{ color: '#1890FF' }}>
          Limpiar Filtros
        </Button>
      </div>
      <Checkbox.Group value={values} onChange={handleChange}>
        <Row gutter={[6, 0]}>
          {categories.map(({ name, countBrands }, index) => (
            <Col key={`${name}${index}`} span={24}>
              <Checkbox value={name} style={{ width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  {name} <span className="count">{countBrands}</span>
                </div>
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </ProviderFiltersStyled>
  )
}
