import { gql } from '@apollo/client'

export const queryListCategories = gql`
  query listCategories($paginated: Paginated, $filter: FilterListCategory) {
    listCategories(paginated: $paginated, filter: $filter) {
      pageInfo {
        total
        currentPage
        perPage
        hasPreviousPage
        hasNextPage
      }
      categories {
        id
        _id
        name
        alias
        isBuyer
        isProvider
        status
        subcategories {
          id
          _id
          name
          alias
          isBuyer
          isProvider
          status
          createdAt
          updatedAt
          categoryVid
          is_active
          industry
          description
        }
        storeBrandsEntity {
          _id
          name
          hasStore
          isActive
        }
        createdAt
        updatedAt
        categoryVid
        is_active
        industry
        description
        imageUrl
        imageMobileUrl
      }
    }
  }
`
