import { gql } from '@apollo/client'

export const queryObtainLegal = gql`
  query getLegal($input: InputLegal) {
    getLegal(input: $input) {
      ruc
      businessName
      statusContributor
      statusResidence
      ubigeo
      businessLocation {
        typeWay
        nameWay
        codeZone
        typeZone
        number
        intern
        department
        province
        district
        address
        fullAddress
      }
      lastUpdated
    }
  }
`

export const queryListFilters = gql`
  query listFilters {
    listFilters {
      brandsAll
      brands
      brandsOthers
      categories
    }
  }
`

export const queryListCategories = gql`
  query listCategories($paginated: Paginated, $filter: FilterListCategory) {
    listCategories(paginated: $paginated, filter: $filter) {
      pageInfo {
        total
      }
      categories {
        _id
        order
        name
        storeBrandsEntity {
          _id
          name
          hasStore
          logoUrl
          imageUrl
        }
      }
    }
  }
`

export const queryListSelectorCategories = gql`
  query selector {
    selector {
      category
      brands {
        _id
        name
        logoUrl
        imageUrl
        hasStore
      }
      subcategories {
        subcategory
      }
    }
  }
`
