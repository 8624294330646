import { LoadingStyled } from './styles'

interface Props {
  loading: boolean
  label?: string
  children: React.ReactNode
}

const Loading = (props: Props) => {
  return (
    <LoadingStyled spinning={props.loading} tip={props.label}>
      {props.children}
    </LoadingStyled>
  )
}

export default Loading
