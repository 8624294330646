import styled, { css } from 'styled-components'
import { Panel } from '../Panel'

export const MenuPanelStyled = styled(Panel)`
  background-color: red;
  transform: translateY(-10000px);
  transition: transform 3s ease-in-out;
  ${({ isOpen }: any) =>
    isOpen &&
    css`
      transform: translateY(0);
    `}
`

const centerStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const PanelContentWrapper = styled.div`
  white-space: initial;
  line-height: normal;
  width: 80vw;
  height: 100%;
  background-color: #fff;
  padding: 1.5rem;
  display: flex;
  gap: 1.5rem;

  .part-left {
    ${centerStyles}
    width: 30%;
    font-size: 3rem;
    font-weight: 600;
  }
  .part-right {
    width: 70%;
    height: 100%;
    overflow: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    & li a::before {
      all: unset !important;
    }
    & li {
      padding: 0.31rem;
    }
    & li:has(ul) {
      display: flex;
      flex-direction: column;
      gap: 0.44rem;
      margin-bottom: 1.5rem;
      .section-wrapper {
        display: flex;
        flex-direction: column;
      }
      .title-brand,
      .title-category {
        font-size: 0.875rem;
        font-weight: 600;
        padding: 0.31rem;
      }
    }
    & li:not(:has(ul)) {
      min-height: 1.75rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &:hover {
        background-color: #d9d9d9;
      }
    }
    .panel-column {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      &:hover {
        background-color: unset;
      }
    }
  }
  .item-label {
    font-weight: bold;
    color: #000;
  }
  .subitem-label {
    color: #000;
  }
  .subitem {
    margin: 0;
    cursor: pointer;
    &:hover {
      background: #d9d9d9;
    }
  }
  .subitem-label {
    display: block;
  }
  .item-label {
    display: block;
    width: fit-content;
    height: fit-content;
  }
`
