import { useMutation, useQuery } from '@apollo/client'

import { queryObtainRequirement } from '../queries/requirement'
import { queryListAddresses } from '../queries/address'
import { mutationAddRequirement, mutationUpdRequirement } from '../mutations/requirements'
import { Input } from '../../graphql.types'
import { Platforms } from 'src/interfaces/types/app'

export const useCreateRequirement = () => {
  return useMutation<any, Input<{ accountId: string; origin: string }>>(mutationAddRequirement)
}

export const useObtainRequirement = (id: string, skip: boolean) => {
  return useQuery(queryObtainRequirement, {
    context: { clientName: Platforms.business },
    variables: { input: { id } },
    skip: skip,
  })
}

export const useModifyRequirement = () => {
  return useMutation<any, any>(mutationUpdRequirement)
}

export const useListAddresses = (accountId: string) => {
  return useQuery<{ listAddresses: string[] }, Input<{ accountId: string }>>(queryListAddresses, {
    context: { clientName: Platforms.business },
    variables: { input: { accountId } },
  })
}
