import styled from 'styled-components'

export const WhatsappContainer = styled.div`
  .wrapper {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 10px;
    z-index: 10;
    right: 10px;
    text-decoration: none;
  }
  .text {
    margin-right: 18px;
    height: 58px;
    padding: 0 20px;
    background: #ffbe2e;
    border-radius: 9px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 2.26347px;
    text-transform: uppercase;
    color: #0d2769;
    position: relative;
    .decor {
      position: absolute;
      height: 23px;
      width: 8px;
      background: #ffbe2e;
      right: -7px;
      clip-path: polygon(0 0, 0% 100%, 100% 50%);
    }
  }
  .icon {
    width: 60px;
    height: 60px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    padding: 12px;
    background-color: #fff;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    align-items: center;
  }
`
