import { BodyStyle } from './styles'

export interface BodyProps {
  children: React.ReactNode
  style?: React.CSSProperties

  // Props for design
  flex?: boolean
  justify?: React.CSSProperties['justifyContent']
  items?: React.CSSProperties['alignItems']
  gap?: React.CSSProperties['gap']
  fDirection?: React.CSSProperties['flexDirection']
}

const Body: React.FC<BodyProps> = ({ children, ...rest }) => {
  return <BodyStyle {...rest}>{children}</BodyStyle>
}

export default Body
