import sizes from 'src/styles/theme/sizes'
import styled from 'styled-components'

export const ItemStyled = styled.div`
  display: flex;
  border-bottom: 1px solid #dee2e6;
  .image {
    display: grid;
    place-items: center;
    width: auto;
    img {
      object-fit: contain;
      max-height: 150px;
    }
  }
  .product-info {
    padding: 20px;
    border-left: 1px solid #dee2e6;
    .title {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin: 0 0 5px;
    }
    .subtitle {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #8c8c8c;
      margin: 0 0 5px;
    }
    .product-footer {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      grid-gap: 10px;
      margin: 20px 0 0;
      @media screen and (min-width: ${sizes.sm}) {
        justify-content: unset;
      }
      label {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        margin: 0 0 10px;
      }
    }
    .column {
      display: flex;
      flex-direction: column;
      min-width: auto;
      @media screen and (min-width: ${sizes.md}) {
        min-width: min(100%, 150px);
      }
      &--end {
        display: flex;
        align-items: flex-end;
      }
      .ant-input-number {
        min-width: 100%;
      }
    }
  }
`
