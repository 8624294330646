class Config {
  platform = {
    env: process.env.REACT_APP_ENVIRONMENT,
    accounts: process.env.REACT_APP_MS_ACCOUNTS,
    business: process.env.REACT_APP_MX_BUSINESS,
    landing: process.env.REACT_APP_ST_LANDING,
    auth: process.env.REACT_APP_ST_AUTH,
    buyer: process.env.REACT_APP_ST_BUYER,
    provider: process.env.REACT_APP_ST_PROVIDER,
    blog: process.env.REACT_APP_WP_BLOG,
  }
  services = {
    aws: {
      region: process.env.REACT_APP_AMZ_REGION,
      accessKeyId: process.env.REACT_APP_AMZ_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AMZ_SECRET_ACCESS_KEY,
    },
    awss3: {
      buckets: {
        coreDomain: process.env.REACT_APP_BUCKET_CORE_DOMAIN,
        requirements: process.env.REACT_APP_BUCKET_REQUIREMENTS || '',
        requirementsDomain: process.env.REACT_APP_BUCKET_REQUIREMENTS_DOMAIN || '',
      },
    },
    ga4: {
      measurementId: process.env.REACT_APP_GA4_TRACKING_ID || '',
    },
    gtm: {
      id: process.env.REACT_APP_GTM_ID || '',
    },
    gcp: {
      key: process.env.REACT_APP_GCP_API_KEY || '',
    },
  }
  web = {
    cellNumber: process.env.REACT_APP_WB_CELLNUMBER,
    email: process.env.REACT_APP_WB_EMAIL,
    fb: process.env.REACT_APP_FB_URL || '',
  }
  files = {
    storePDF: process.env.REACT_APP_STORE_PDF || '',
    maxSize: process.env.REACT_APP_FILE_MAX_SIZE || '',
    maxCount: process.env.REACT_APP_FILE_MAX_COUNT || '',
  }
  templates = {
    requirement: process.env.REACT_APP_PLT_REQUIREMENT,
  }
}

export default new Config()
