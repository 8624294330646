import { useEffect, useState } from 'react'

import { IStructure, Portal } from 'src/interfaces/structure'

import { listFavorites, useObtainStructure } from 'src/api/graphql'

import { Slider, Brands, Categories, Products } from 'src/pages/Home/components'

import { QContainer } from 'src/shared/components'

import { Benefits } from '../components/Benefits'
import { Ads } from '../components/Ads'
import SubBrands from '../components/SubBrands'
import SubFooter from '../components/SubFooter'
import Sponsors from '../components/Sponsors'

enum Section {
  banners = 'banners',
  brands = 'brands',
  products = 'products',
  categories = 'categories',
}

interface IFavorite {
  section: Section
  names: string[]
}

const HomeLayout = () => {
  const [favorites, setFavorites] = useState<IFavorite[]>([])
  const [structure, setStructure] = useState<IStructure | null>(null)

  const qFavorites = listFavorites()
  const qStructure = useObtainStructure({ portal: Portal.store })

  useEffect(() => {
    if (!qFavorites.loading) {
      if (qFavorites.data) setFavorites([...qFavorites.data.listFavorites])
    }
  }, [qFavorites.loading, qFavorites.data])

  useEffect(() => {
    if (!qStructure.loading && qStructure.data) {
      setStructure(qStructure.data?.obtainStructure)
    }
  }, [qStructure.loading, qStructure.data])

  return (
    <>
      <Slider images={structure?.banners} />
      <QContainer>
        <Brands
          names={favorites.find((fav: IFavorite) => fav.section == Section.brands)?.names ?? []}
        />
      </QContainer>
      <QContainer>
        <Benefits benefits={structure?.benefits ?? []} />
      </QContainer>
      <QContainer>
        <Products
          names={favorites.find((fav: IFavorite) => fav.section == Section.products)?.names ?? []}
        />
      </QContainer>
      <QContainer>
        <Categories
          names={favorites.find((fav: IFavorite) => fav.section == Section.categories)?.names ?? []}
        />
      </QContainer>
      <QContainer>
        <Ads type="equip" />
      </QContainer>
      <br />
      <QContainer>
        <Ads type="product" />
      </QContainer>
      <QContainer>
        <SubBrands />
      </QContainer>
      <SubFooter />
      <Sponsors />
    </>
  )
}

export default HomeLayout
