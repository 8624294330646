import styled from 'styled-components'

import { BodyProps } from './index'

function flexStyles({ flex, justify, items, gap, fDirection }: BodyProps) {
  let stringStyles = ''
  if (flex) {
    stringStyles = stringStyles + 'display: flex;'
  }
  if (fDirection) {
    stringStyles = stringStyles + `flex-direction: ${fDirection};`
  }
  if (justify) {
    stringStyles = stringStyles + `justify-content: ${justify};`
  }
  if (items) {
    stringStyles = stringStyles + `align-items: ${items};`
  }
  if (gap) {
    stringStyles = stringStyles + `gap: ${gap};`
  }

  return stringStyles
}

export const BodyStyle = styled.div<BodyProps>`
  display: block;
  ${flexStyles}
`
