export function validateRUC(ruc: string) {
  if (!ruc) return false
  const codes = ['10', '15', '16', '17', '20']
  return ruc?.length === 11 && codes.includes(ruc.substring(0, 2))
}

export function validateToken(str: string) {
  const pattern = /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/
  return pattern.test(str)
}

export function validateExcelFiletype(str: string) {
  const pattern = /xlsx?|xls?$/
  return pattern.test(str)
}

export function validatePdfFiletype(str: string) {
  const pattern = /pdf?$/
  return pattern.test(str)
}

export function validateImgFiletype(str: string) {
  const pattern = /jpeg?|png?|jpg?$/
  return pattern.test(str)
}

export function validateFiletype(str: string) {
  const pattern = /jpeg?|png?|pdf?|xlsx?|xls?|jpg?|doc?|docx?$/
  return pattern.test(str)
}

export function validateFilename(str: string) {
  const pattern = /^[A-Za-z0-9/ -_.][^+]*$/
  return pattern.test(str)
}

export function validateFoldername(str: string) {
  const pattern = /^[A-Za-z0-9/ -_]*$/
  return pattern.test(str)
}

export function validateEmail(str: string) {
  if (!str) return false
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return !str.includes('ñ') ? pattern.test(str.toLowerCase()) : false
}

export function validateCellphone(str: string) {
  if (!str) return false
  const pattern = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g
  return !str.includes('ñ') ? pattern.test(str) : false
}

export function validateObjectId(str: string) {
  if (!str) return false
  const pattern = /^[0-9a-fA-F]{24}$/
  return !str.includes('ñ') ? pattern.test(str) : false
}

export function validateUrl(str: string) {
  const pattern = /^(https?|ftp|irc):\/\/(-\.)?([^\s\/?\.#-]+\.?)+(\/[^\s]*)?$/i
  return pattern.test(str)
}

export function validateResponseCodeError(str: string): number | undefined {
  if (!str) return
  return parseInt(str.split(':')[0])
}

export function validateHash(str?: string) {
  if (!str) return false
  if (str.length < 10) return false
  return true
}

export function validateProductId(str?: string) {
  if (!str) return false
  const rgx = /^(?=[a-f\d]{24}$)/
  return rgx.test(str)
}
