import sizes from 'src/styles/theme/sizes'
import styled from 'styled-components'

export const ProvidersWrapper = styled.section`
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  @media screen and (min-width: ${sizes.lg}) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`

export const WrapperFilterDesktopStyled = styled.div`
  display: none;
  @media screen and (min-width: ${sizes.lg}) {
    display: block;
  }
`

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.25rem;
  margin-bottom: 0.5rem;
  .providers-title {
    font-size: 1.5rem;
    font-weight: 700;
  }
  .provider-search {
    width: 100%;
  }

  @media screen and (min-width: ${sizes.lg}) {
    margin-bottom: 2.5rem;
    justify-content: flex-end;
    .providers-search {
      max-width: 27.5rem;
    }
    .providers-filter-btn {
      display: none;
    }
  }
`

export const ProvidersPageBodyStyled = styled.section`
  display: flex;
  align-items: flex-start;
  gap: 1.25rem;
`
