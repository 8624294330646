import { Suspense } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Spin } from 'antd'

import AuthProvider from './auth/authProvider'

import { routes } from './routes'

const App = () => {
  const router = createBrowserRouter(routes)
  return (
    <AuthProvider>
      <Suspense fallback={<Spin spinning={true} />}>
        <RouterProvider router={router} />
      </Suspense>
    </AuthProvider>
  )
}

export default App
