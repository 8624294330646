import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client'

import { Platforms } from 'src/interfaces/types/app'

import config from 'src/config/app'

const businessLink = createHttpLink({
  uri: config.platform.business + '/graphql',
})

const accountsLink = createHttpLink({
  uri: config.platform.accounts + '/graphql',
})

const client = new ApolloClient({
  // uri: `${config.platform.enterprise}/graphql`,
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName == Platforms.accounts,
    accountsLink,
    ApolloLink.split(
      (operation) => operation.getContext().clientName == Platforms.business,
      businessLink
    )
  ),
  cache: new InMemoryCache(),
})

export * from './resolvers/accounts'
export * from './resolvers/commons'
export * from './resolvers/inventories'
export * from './resolvers/buyers'
export * from './resolvers/requirements'
export * from './commons/resolvers'
export * from './requirement/resolvers'
export * from './inventories/resolvers'
export * from './brands/resolvers'
export * from './projects/resolvers'

export default client
