import { useDispatch, useSelector } from 'react-redux'

import { Product } from 'src/interfaces/ecommerce'

import { addToCart, cleanCart, removeOneFromCart, updOneCart } from 'src/redux/states/cart'

import { useAddToCart, useRemoveFromCart } from 'src/services/gtm'

import { AppStore } from '../../redux/store'

import { QNotify } from '../components'

export const useCart = () => {
  const dispatch = useDispatch()

  const products = useSelector((state: AppStore) => state.cart)

  const addProductToCart = (product: Product) => {
    useAddToCart(product)
    dispatch(addToCart(product))
    QNotify({
      type: 'success',
      message: '¡Producto agregado!',
      placement: 'topLeft',
    })
  }
  const updProduct = (product: Product) => {
    dispatch(updOneCart(product))
  }

  /*const removeProductFromCart = (product: Product): void => {
    dispatch(removeOneFromCart({ product }))
  }*/

  const removeEntireProductFromCart = (product: Product): void => {
    useRemoveFromCart(product)
    dispatch(removeOneFromCart({ product, removeAll: true }))
  }

  const clearCart = (): void => {
    dispatch(cleanCart())
  }

  return {
    products,
    addProductToCart,
    updProduct,
    // removeProductFromCart,
    removeEntireProductFromCart,
    clearCart,
  }
}
