import { CheckboxOptionType } from 'antd'

class Format {
  capitalizeFirstLetter = (str?: string) => {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : ''
  }

  splitCommaNoSpace = (str: string) => {
    return str
      .replace(', ', '--')
      .split(',')
      .map((s) => s.replace('--', ', '))
  }
  toCheckOptions = (arr?: { name: string }[]): CheckboxOptionType[] => {
    return (
      arr
        ?.filter(({ name }) => name)
        ?.map((item, index: number) => {
          return { label: item.name, value: item.name, key: item.name + index }
        }) ?? []
    )
  }
}

export default new Format()
