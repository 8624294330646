import { Input, Card, Select } from 'antd'
import styled from 'styled-components'

import Colors from '../../shared/theme/colors'

const { Password } = Input

export const PageStyle = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  flex-wrap: wrap;
  /*height: calc(100vh - 45px);*/
  height: 90vh;
  @media (min-width: 576px) {
    grid-template-rows: unset;
    grid-template-columns: auto 3fr;
  }
`

export const StepsStyle = styled.div`
  padding: 20px 25px 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .title {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
  }
  @media (min-width: 576px) {
    padding: 20px 45px 40px;
    gap: 50px;
    .title {
      text-align: unset;
      font-size: 32px;
    }
  }
`

export const ContentStyle = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: white;
  padding: 20px 40px 45px;
  .step-2-container {
    width: 100%;
  }
  h5 {
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 20px;
  }
  .form-title {
    font-weight: 600;
    font-size: 28px;
    margin: 20px 0;
  }
  form {
    display: flex;
    flex-direction: column;
    max-width: 800px;

    p {
      color: #00000073;
      margin-bottom: 0;
    }

    .form-header {
      display: flex;
      align-items: center;
      gap: 5px;
      flex-wrap: wrap;
      margin-bottom: 20px;
    }

    .subtitle {
      font-size: 24px;
      font-weight: 700;

      &-link {
        font-size: 16px;
        font-weight: 400;
        color: ${Colors.blue};

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .checkboxes {
    a {
      color: #1890ff;
    }
    label {
      font-size: 14px;
      font-weight: 400;
      margin: 0 !important;
    }

    span {
      border-radius: 2px;
    }
  }
  .actions {
    text-align: end;

    &-between {
      display: flex;
      justify-content: space-between;

      @media (max-width: 576px) {
        padding-bottom: 60px;
      }
    }

    @media (max-width: 576px) {
      padding-bottom: 60px;
    }
  }
  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 20px;

    border-radius: 8px;

    @media (max-width: 576px) {
      padding-bottom: 60px;
    }
  }
  & form {
    padding: initial;
    min-width: 100%;
  }
`

export const InputStyle = styled(Input)`
  height: 48px;
  border-radius: 8px;

  & input {
    text-align: center;
  }
`

export const PasswordStyle = styled(Password)`
  height: 48px;
  border-radius: 8px;

  & input {
    text-align: center;
  }
`

export const SelectStyle = styled(Select)`
  .ant-select-selector {
    height: 48px;
    border-radius: 8px !important;
    text-align: center !important;

    & input {
      text-align: center;
    }
  }
`

export const CardStyle = styled(Card)`
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  .ant-card-body {
    padding: 30px 15px 15px;
    height: 100%;
  }
  .grid {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    grid-gap: 10px;
    height: 100%;
    img {
      width: 75px;
      max-height: 75px;
      filter: grayscale(1);
      opacity: 0.45;
    }
  }

  span {
    color: ${Colors.blueDark};
    font-weight: 700;
    font-size: 16px;
    filter: grayscale(1);
    opacity: 0.45;
  }

  &:hover {
    box-shadow: 0px 20px 60px rgba(17, 29, 94, 0.3);
    cursor: pointer;
    border: none;
    img,
    span {
      filter: unset;
      opacity: 1;
    }
  }
`

export const FinishStyle = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

export const CardResultStyle = styled(Card)`
  border-radius: 8px;
  max-width: 510px;
  .ant-result {
    padding: unset;
  }
  .ant-result-icon {
    margin-bottom: 14px;
  }
  .ant-result-title {
    font-weight: 700;
    font-size: 34px;
  }
  .ant-result-subtitle {
    font-size: 18px;
    color: black;
  }
`
