import { useNavigate, useSearchParams } from 'react-router-dom'
import { listCategories } from '../../api/graphql'

export enum FilterKeyEnum {
  q = 'q',
  categories = 'categories',
  brands = 'brands',
}

export type FilterKey = keyof typeof FilterKeyEnum

export type FilterItem = [FilterKeyEnum | FilterKey, string | null]

export type NavigateFilterArgs = FilterItem[]

export const useFilters = () => {
  const navigate = useNavigate()
  const [searchParams, _setParams] = useSearchParams()

  const getQueryParams = (args: FilterKeyEnum[]): Record<FilterKeyEnum, string | null> => {
    const params = args.map((filterLabel) => {
      return [filterLabel, searchParams.get(filterLabel) ?? undefined]
    })

    const res = params.reduce(
      (acc, item) => {
        const [label, value] = item
        return { ...acc, [label as any]: value }
      },
      { q: null, categories: null, brands: null }
    )

    return res
  }

  const applyNavigateFilter = (path: string, args: NavigateFilterArgs) => {
    const urlParams = args
      .filter((item) => item[1]) // Si el valor que se le pasa al filter es invalido, se elimina de los query params
      .map((item) => {
        const [filter, value] = item
        return `${filter}=${value?.replace('&', '%26').replace('#', '%23')}`
      })
      .join('&')

    navigate({
      pathname: path,
      search: urlParams,
    })
  }

  return { applyNavigateFilter, getQueryParams }
}
