import styled from 'styled-components'

export const SliderStyled = styled.div`
  img {
    width: 100%;
    object-fit: contain;
  }
  .react-slideshow-container .nav {
    z-index: 9;
  }
  .nav.default-nav {
    margin: 0 40px;
  }
`

export const EachSliderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`
