import { CheckboxOptionType } from 'antd/lib/checkbox/Group'
import format from 'src/shared/helpers/format'
import { FilterCategory } from '.'

export function moveElementsToStart(arr: string[], elementsToMove: string[]) {
  const indices = elementsToMove.map((element) => arr.indexOf(element))
  const filteredIndices = indices.filter((index) => index !== -1)

  if (filteredIndices.length > 0) {
    const newElements = filteredIndices.map((index) => arr[index])
    const newArray = [...newElements, ...arr.filter((_, index) => !filteredIndices.includes(index))]

    return newArray
  }

  return arr
}

export function moveCheckedsToStart(arr: CheckboxOptionType[], elementsToMove: string[]) {
  const plainArr = arr.map(({ value }) => String(value))
  const newSortedArray = moveElementsToStart(plainArr, elementsToMove)
  return format.toCheckOptions(newSortedArray.map((v) => ({ name: v })))
}

export function moveCheckedsToStartCategories(
  arr: FilterCategory[],
  elementsToMove: string[]
): FilterCategory[] {
  const plainArr = arr.map(({ key }) => String(key))
  const newSortedArray = moveElementsToStart(plainArr, elementsToMove)
  const sortArr = arr.sort((a, b) => {
    const aKey = a.key?.toString()
    const bKey = b.key?.toString()
    return newSortedArray.indexOf(aKey) - newSortedArray.indexOf(bKey)
  })
  return sortArr
}
