import styled from 'styled-components'
import colors from 'src/styles/theme/colors'
import sizes from 'src/styles/theme/sizes'

const fontFamily = 'Poppins'
export const SponsorsStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  @media screen and (min-width: ${sizes.md}) {
    padding: 65px 0;
  }
  .title {
    font-weight: 700;
    font-size: 24px;
    font-family: ${fontFamily};
    text-align: center;
    color: ${colors.black};
    @media screen and (min-width: ${sizes.md}) {
      font-size: 36px;
    }
  }
  .grid-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .image {
    flex: 0 0 calc(20% - 20px);
    height: auto;
  }
`
