import { Button, Input, Modal } from 'antd'
import sizes from 'src/styles/theme/sizes'
import styled, { css } from 'styled-components'

export const RequirementModalStyled = styled(Modal)`
  width: 90vw !important;
  padding: 0 !important;
  border-radius: 0.5rem;
  overflow: hidden;
  .ant-modal-content {
    padding: 0 !important;
  }
  @media screen and (min-width: ${sizes.md}) {
    width: 800px !important;
  }
`

const restMarginStyles = css`
  margin: 0;
`

export const TitleStyled = styled.h1`
  ${restMarginStyles}
  font-weight: 700;
  font-size: 2rem;
  line-height: 122.5%;
  max-width: 22.3125rem;
  @media screen and (min-width: ${sizes.lg}) {
    font-size: 2.25rem;
  }
`

export const SloganStyled = styled.p`
  ${restMarginStyles}
  font-weight: 400;
  font-size: 0.875rem;
  line-height: normal;
  max-width: 22.3125rem;
  @media screen and (min-width: ${sizes.lg}) {
    font-size: 1.125rem;
  }
`

export const FormTitleStyled = styled.h3`
  font-weight: 600;
  font-size: 1.25rem;
  line-height: normal;
`

export const HighlightedTextStyled = styled.span`
  background: linear-gradient(279deg, #ff9d0b 9%, #ff7a00 57.28%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-feature-settings: 'clig' off, 'liga';
  font-size: 2rem;
`

export const InputStyled = styled(Input)`
  padding: 0.625rem 0.75rem;
  border-radius: 0.5rem;
  background: #d9d9d9;
`

export const ButtonStyled = styled(Button)`
  border-radius: 0.5rem;
  background: linear-gradient(106deg, #ff9d0b 0.52%, #ff7a00 100%);
  font-weight: 500;
  color: #000;
  &:hover,
  &:focus,
  &:active {
    border-color: #ff7a00 !important;
    background: linear-gradient(106deg, #ff9d0b 0.52%, #ff7a00 100%);
    color: #000;
  }
  span {
    color: #000;
  }
`

export const PartLeftStyled = styled.section`
  padding: 2.5rem;
  background-color: #f0f0f0;
  height: 100%;
`

export const PartRightStyled = styled.section`
  padding: 1.5rem;
`

export const BillingInputsStyled = styled.section`
  .ant-input {
    border-radius: 0;
  }
  .ant-input:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .ant-input:last-child {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
`
