import React from 'react'

import { WspIcon } from './Icon'

import config from '../../../config/app'

import { WhatsappContainer } from './styles'

const wsp_endpoint = `https://api.whatsapp.com`
const cellNumber = config.web.cellNumber?.replace(/([\+\ \&])/g, '')
const fbLink = config.web.fb
const message = 'Hola, me gustaría solicitar una cotización rápida.'

export const WhatsAppFloat: React.FC = () => {
  return (
    <WhatsappContainer>
      <a
        className="wrapper"
        href={`${wsp_endpoint}/send?phone=${cellNumber}&text=${message}`}
        // href={fbLink}
        target="_blank"
        rel="noreferrer"
      >
        <div
          className="click-btn-whatsapp"
          style={{
            marginRight: 18,
            height: 58,
            paddingLeft: 20,
            paddingRight: 20,
            background: '#ffbe2e',
            borderRadius: 9,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontStyle: 'normal',
            fontWeight: 800,
            fontSize: 16,
            letterSpacing: 2.26347,
            textTransform: 'uppercase',
            color: '#0d2769',
            position: 'relative',
          }}
        >
          Cotización rápida
          <div
            className="decor"
            style={{
              position: 'absolute',
              height: 23,
              width: 8,
              background: '#ffbe2e',
              right: -7,
              clipPath: 'polygon(0 0, 0% 100%, 100% 50%)',
            }}
          ></div>
        </div>
        <div
          className="click-btn-whatsapp"
          style={{
            width: 60,
            height: 60,
            boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
            padding: 12,
            backgroundColor: '#fff',
            boxSizing: 'border-box',
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '50%',
            alignItems: 'center',
          }}
        >
          <WspIcon width={88} height={88} />
          {/*<img src="icons/facebook-logo.png" width={88} height={88} />*/}
        </div>
      </a>
    </WhatsappContainer>
  )
}
