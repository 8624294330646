enum CurrencyTypes {
  USD = 'usd',
  PEN = 'pen',
}

export const currencyTypes = Object.values(CurrencyTypes)

export const zanitizeCurrency = (currency: string): CurrencyTypes => {
  const regexPEN = /^pen$/i
  const regexUSD = /^usd$/i
  return regexPEN.test(currency)
    ? CurrencyTypes.PEN
    : regexUSD.test(currency)
    ? CurrencyTypes.USD
    : CurrencyTypes.PEN
}
