import { gql } from '@apollo/client'

export const queryFilters = gql`
  query filters($input: InputFilters) {
    filters(input: $input) {
      categories {
        category
        subcategories {
          subcategory
        }
      }
      brand
    }
  }
`
