export const numberSizes = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 2000,
}

class Size {
  xs: string = numberSizes.xs + 'px'
  sm: string = numberSizes.sm + 'px'
  md: string = numberSizes.md + 'px'
  lg: string = numberSizes.lg + 'px'
  xl: string = numberSizes.xl + 'px'
  xxl: string = numberSizes.xxl + 'px'
}

export default new Size()
