import { Button } from 'antd'
import styled from 'styled-components'

const equipColor = {
  primary: '#131a5e',
  secondary: '#FF9D0B',
}

export const AntButtonStyled = styled(Button)`
  &.withHover-primary:hover {
    background-color: ${equipColor.primary};
    color: white !important;
  }
  &.withHover-secondary:hover {
    background-color: ${equipColor.secondary};
    color: white !important;
  }
`
