import { useState } from 'react'

export const useInactive = (time = 1000) => {
  const [interval, setInterval] = useState<NodeJS.Timeout>()
  const [isInactive, setIsInactive] = useState<boolean | undefined>(undefined)
  const validateActivity = (whatDo?: () => void) => {
    clearInterval(interval)
    setIsInactive(false)
    setInterval(
      setTimeout(() => {
        whatDo?.()
        setIsInactive(true)
      }, time)
    )
  }
  const clear = () => {
    clearInterval(interval)
  }
  return {
    isInactive,
    isWriting: isInactive === false,
    isStateInitial: isInactive === undefined,
    validateActivity,
    clear,
  }
}
