import { dividerArr } from 'src/shared/utils/arrays'
import { BrandsByCategory } from '../hooks/useListFilters'

interface BrandsPanelContentProps {
  brandsByCategory: BrandsByCategory
  handleClose?: () => void
}

export const BrandsPanelContent = ({ brandsByCategory, handleClose }: BrandsPanelContentProps) => {
  const brandsByCategoryDivided = dividerArr(brandsByCategory, 3)
  return (
    <>
      {brandsByCategoryDivided.map((brandsByCategory, index) => (
        <li className="panel-column" key={index}>
          {brandsByCategory.map((brandItem, index) => (
            <div className="section-wrapper" key={`${brandItem.category}-${index}`}>
              <span className="item">
                <a
                  className=" item-label"
                  href={`/products?categories=${brandItem.category}`}
                  onClick={() => handleClose?.()}
                >
                  {brandItem.category}
                </a>
              </span>
              {brandItem.brands.length > 0 && (
                <ul>
                  {brandItem.brands.map((brand: any, index: any) => {
                    return (
                      <li key={index} className="subitem">
                        <a
                          className="subitem-label"
                          onClick={() => handleClose?.()}
                          href={
                            brand?.hasStore
                              ? `/providers/${brand.label}`
                              : `/products?brands=${brand.label}`
                          }
                        >
                          {brand.label}
                        </a>
                      </li>
                    )
                  })}
                </ul>
              )}
            </div>
          ))}
        </li>
      ))}
    </>
  )
}
