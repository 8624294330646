import styled from 'styled-components'
import { Checkbox, Modal, Space } from 'antd'

import colors from 'src/styles/theme/colors'
import sizes from 'src/styles/theme/sizes'

export const NavbarStyled = styled.div`
  padding: 0.5rem 1.25rem 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px 0;
  align-items: center;
  background-color: white;
  @media screen and (max-width: ${sizes.md}) {
    padding: 0.5rem 1.25rem 1.25rem;
  }
  .grid {
    display: flex;
    gap: 20px;
  }
  .nav-search {
    order: 1;
    margin-top: auto;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    & > .ant-space-compact {
      width: 100%;
    }
    @media screen and (min-width: ${sizes.lg}) {
      order: initial;
      & > .ant-space-compact {
        width: 650px;
      }
    }
  }

  @media screen and (min-width: ${sizes.lg}) {
    height: 7.1875rem;
    flex-wrap: nowrap;
  }
  @media screen and (min-width: ${sizes.xl}) {
    padding: 0 2.5em;
  }

  .logo-desktop {
    display: block;

    @media screen and (max-width: ${sizes.sm}) {
      display: block;
    }
  }

  .logo-mobile {
    display: none;

    @media screen and (max-width: ${sizes.sm}) {
      display: block;
    }
  }
`

export const SearchGroup = styled(Space.Compact)`
  width: 100%;
  display: inline-flex;
  .ant-btn-primary {
    background-color: ${colors.primary};
    border-color: ${colors.primary};
    color: ${colors.black};
    &.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
      background-color: ${colors.primary};
    }
  }
  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    border-radius: 0 8px 8px 0;
  }
  .ant-select.ant-select-lg {
    display: inline-block;
    @media screen and (min-width: ${sizes.md}) {
      display: inline-block;
    }
  }
  .ant-select-auto-complete {
    width: 100%;
    @media screen and (min-width: ${sizes.md}) {
      /* width: 100%; */
    }
  }
  .text-md {
    display: none;
    margin-left: 6px;
    @media screen and (min-width: ${sizes.md}) {
      display: inline-block;
    }
  }
`

export const MenuStyled = styled.div`
  display: none;
  height: 100%;
  width: 100%;
  @media screen and (min-width: ${sizes.md}) {
    display: block;
  }
`

export const MenuIconStyled = styled.div`
  display: block;

  @media screen and (min-width: ${sizes.md}) {
    display: none;
  }
`

export const NavIconsWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  @media screen and (min-width: ${sizes.md}) {
    gap: 0.5rem;
  }
`

export const CheckboxStyled = styled(Checkbox)`
  display: none;
  @media screen and (min-width: ${sizes.sm}) {
    display: inline-flex;
  }
`

export const SearchMobile = styled.div`
  & .ant-input-group-addon {
    display: none;
  }
  height: 127px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background: #fff;
  width: 100%;
  display: flex;
  grid-gap: 10px;
  flex-direction: column;
  justify-content: space-around;
  padding: 0.5rem 0;
  @media screen and (min-width: ${sizes.sm}) {
    display: none;
  }
`
