export enum Portal {
  store = 'store',
  auth = 'auth',
  buyer = 'buyer',
  provider = 'provider',
}

export interface IStructure {
  _id?: string
  portal: Portal
  banners: Banner[]
  benefits: Benefit[]
}

export interface Banner {
  imageUrl: string
  imageMobileUrl: string
  redirectTo: string
}

export interface Benefit {
  title: string
  description: string
  iconUrl: string
}

export interface ImageBanner {
  mobileUrl: string
  desktopUrl: string
  caption?: string
  redirect?: string
}
