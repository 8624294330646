import { CreateBuyer, CreateProduct, Inventory } from 'src/interfaces/backend'
import { Client, Product } from 'src/interfaces/ecommerce'
import { Origins } from 'src/interfaces/types/app'
import { Banner, ImageBanner } from 'src/interfaces/structure'

import { zanitizeCurrency } from '../utils/currency'

class Transforms {
  inventory2product = (inventory: Inventory): Product => {
    return {
      id: inventory.key,
      name: inventory.product,
      description: inventory.description,
      brand: inventory.brand,
      categories: inventory.categories,
      skuEquip: inventory.skuEquip ?? '',
      imageUrl: inventory.image0,
      imageUrl1: inventory.image1,
      techsheetUrl: inventory.techsheetUrl,
      price: inventory.price,
      currency: zanitizeCurrency(inventory.currency),
      inventoryId: inventory.key,
      qty: 1,
    }
  }

  inv2productImages = (inventory: Inventory): string[] => {
    return [inventory?.image0, inventory?.image1, inventory?.image2, inventory?.image3].filter(
      (img) => img
    )
  }

  client2buyer = (client: Client): CreateBuyer => {
    return {
      name: client.name,
      lastname: client.lastname,
      cellNumber: client.cellphone,
      email: client.email,
      ruc: client.ruc,
      company: client.company,
      address: client.address,
      origin: Origins.store,
      isTerms: true,
    }
  }

  product2bproduct = (index: number, product: Product): CreateProduct => {
    return {
      index,
      name: product.name,
      brand: product.brand,
      unit: 'UND',
      quantity: product.qty ?? 0,
      inventoryId: product.id,
      inventoryVid: '',
    }
  }

  banners2images = (banners: Banner[]): ImageBanner[] => {
    return banners.map((banner: Banner) => ({
      mobileUrl: banner.imageMobileUrl,
      desktopUrl: banner.imageUrl,
      caption: '',
      redirect: banner.redirectTo,
    }))
  }
}

export default new Transforms()
