import { useQuery, useLazyQuery } from '@apollo/client'

import { Input, Paginated, ResponsePaginated } from '../../graphql.types'
import { Platforms } from 'src/interfaces/types/app'

import { queryGetLegal } from '../queries/getLegal'
import { queryListCategories } from '../queries/getCategories'
import { queryFilters } from '../queries/filters'
import { queryListDistricts } from '../queries/listDistricts'
import { queryListDepartments } from '../queries/listDepartments'
import { Ubigeo } from '../../../../interfaces/ubigeo'

export type InputLegal = Input<{ ruc: string }>

export const useLazyGetLegal = () => {
  return useLazyQuery<{ getLegal: any }, InputLegal>(queryGetLegal, {
    context: { clientName: Platforms.business },
  })
}

export const useListCategories = (paginated: Paginated, filter?: any) => {
  return useQuery<{ listCategories?: ResponsePaginated<'categories'> }>(queryListCategories, {
    context: { clientName: Platforms.business },
    variables: { paginated, filter },
  })
}

export const useListDistricts = (paginated: Paginated, filter?: { q?: string }) => {
  return useQuery<{ listDistricts?: ResponsePaginated<'districts'> }>(queryListDistricts, {
    context: { clientName: Platforms.business },
    variables: { paginated, filter },
  })
}

export type ResponseDistricts = {
  listDistricts: ResponsePaginated<'districts'>
}
export const useLazyListDistricts = () => {
  return useLazyQuery<ResponseDistricts>(queryListDistricts, {
    context: { clientName: Platforms.business },
  })
}

export const useListFilters = (input?: any) => {
  return useQuery<{ filters?: any }>(queryFilters, {
    context: { clientName: Platforms.business },
    variables: { input },
  })
}

export type ResponseDepartments = { listDepartments?: Ubigeo[] }
export const useListDepartments = () => {
  return useQuery<ResponseDepartments>(queryListDepartments, {
    context: { clientName: Platforms.business },
    variables: {},
  })
}
