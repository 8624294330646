import { FC } from 'react'
import { ConfigProvider, Button as AntButton, ButtonProps as AntButtonProps } from 'antd'

import { AntButtonStyled } from './styles'

export interface ButtonProps extends AntButtonProps {
  equip?: 'primary' | 'secondary' | 'default'
  isHovered?: boolean
}

const Button: FC<ButtonProps> = ({ equip = 'default', isHovered, children, ...rest }) =>
  equip === 'default' ? (
    <AntButton {...rest}>{children}</AntButton>
  ) : equip === 'primary' ? (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#030852',
        },
      }}
    >
      <AntButtonStyled type="primary" className={isHovered ? 'withHover-primary' : ''} {...rest}>
        {children}
      </AntButtonStyled>
    </ConfigProvider>
  ) : (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#FF9D0B',
        },
      }}
    >
      <AntButtonStyled
        type="primary"
        className={isHovered ? 'withHover-secondary' : ''}
        style={{ color: 'black' }}
        {...rest}
      >
        {children}
      </AntButtonStyled>
    </ConfigProvider>
  )

export default Button
