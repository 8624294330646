import Button from 'src/shared/components/Button'

import { SubFooterStyled } from './styles'

const SubFooter = () => {
  return (
    <SubFooterStyled>
      <div className="container">
        <div className="header">
          <img src="/logos/logo-orange.svg" height="40" width="137" />
          <span className="title">Nuestro impacto</span>
        </div>
        <Button equip="secondary" className="button" href="/register">
          Registrarse
        </Button>

        <div className="figures">
          <div className="col">
            <span className="title">+100</span>
            <span className="subtitle">Constructoras confían en nosotros</span>
          </div>

          <div className="col">
            <span className="title">$30M</span>
            <span className="subtitle">Millones en requerimientos</span>
          </div>

          <div className="col">
            <span className="title">+2k</span>
            <span className="subtitle">Entregas exitosas</span>
          </div>
        </div>
      </div>
    </SubFooterStyled>
  )
}

export default SubFooter
