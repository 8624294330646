import { Link } from 'react-router-dom'
import { Affix } from 'antd'

import { useDialog } from 'src/shared/hooks'

import Search from './Search'
import Menu from './Menu'
import { CartBtn } from './CartBtn'
import { UserPopover } from './UserPopover'

import { NavbarStyled } from './styles'

const Navbar = () => {
  const modalReq = useDialog()
  return (
    <Affix offsetTop={0} style={{ position: 'relative', zIndex: 100 }}>
      <NavbarStyled>
        <Link className="logo-desktop" to="/">
          <img src="/logos/logo.svg" height="40" alt="Equip" />
        </Link>
        <nav className="nav-search">
          <Search />
          <Menu modalReq={modalReq} />
        </nav>
        <div className="grid">
          <CartBtn modalReq={modalReq} />
          <UserPopover />
        </div>
      </NavbarStyled>
    </Affix>
  )
}

export default Navbar
