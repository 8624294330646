import { useMutation, useQuery } from '@apollo/client'

import { mutationAddRequirement, mutationAddProducts } from '../mutations/requirements'

export const createRequirement = () => {
  const response = useMutation(mutationAddRequirement)
  return response
}

export const createProducts = () => {
  const response = useMutation(mutationAddProducts)
  return response
}
