import { createSlice } from '@reduxjs/toolkit'

import { Product } from 'src/interfaces/ecommerce'
import { LocalStorageEntities } from 'src/interfaces/types/app'

import storage from 'src/shared/helpers/storage'

export const CartEmptyState: Product[] = []

interface CartAction<T> {
  type: string
  payload: T
}

interface RemovePayload {
  product: Product
  removeAll?: boolean
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState:
    storage.getDataLocalStorage<Product[]>(LocalStorageEntities.products) || CartEmptyState,
  reducers: {
    addToCart: (state, action: CartAction<Product>) => {
      const hasEditableProduct = state.some((product: Product) => product.id === action.payload.id)

      const cartProducts: Product[] = hasEditableProduct
        ? state.map((product: Product) => {
            return product.id === action.payload.id
              ? { ...product, qty: (product?.qty || 1) + (action.payload?.qty || 1) }
              : product
          })
        : [...state, action.payload]

      storage.persistDataLocalStorage<Product[]>({
        data: cartProducts,
        entity: LocalStorageEntities.products,
      })
      return cartProducts
    },
    removeOneFromCart: (state, action: CartAction<RemovePayload>) => {
      const productId = action.payload.product.id
      const isRemoveAll = action.payload.removeAll
      const isRemovable = state.some(
        (product: Product) => product.id === productId && product.qty === 1
      )
      const cartProducts = isRemoveAll
        ? state.filter((product: Product) => product.id !== productId)
        : isRemovable
        ? state.filter((product: Product) => product.id !== productId)
        : state.map((product: Product) => {
            return product.id === productId ? { ...product, qty: (product?.qty || 1) - 1 } : product
          })

      storage.persistDataLocalStorage<Product[]>({
        data: cartProducts,
        entity: LocalStorageEntities.products,
      })
      return cartProducts
    },
    updOneCart: (state, action: CartAction<Product>) => {
      const productId = action.payload.id
      const cartProducts = state.map((product: Product) =>
        product.id === productId ? { ...product, qty: action.payload.qty } : product
      )

      storage.persistDataLocalStorage<Product[]>({
        data: cartProducts,
        entity: LocalStorageEntities.products,
      })
      return cartProducts
    },
    cleanCart: () => {
      storage.persistDataLocalStorage<Product[]>({
        data: CartEmptyState,
        entity: LocalStorageEntities.products,
      })
      return CartEmptyState
    },
  },
})

export const { addToCart, removeOneFromCart, cleanCart, updOneCart } = cartSlice.actions

export default cartSlice.reducer
