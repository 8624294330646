import React from 'react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom/client'
import { ApolloProvider } from '@apollo/client'
import TagManager from 'react-gtm-module'

import { store } from 'src/redux'

import App from './App'

import client from 'src/api/graphql'

import GlobalStyle from './styles/globals'

import config from 'src/config/app'

const tagManagerArgs = {
  gtmId: config.services.gtm.id,
}

TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(
  <>
    <GlobalStyle />
    <ApolloProvider client={client}>
      <Provider store={store}>
        <App />
      </Provider>
    </ApolloProvider>
  </>
)
