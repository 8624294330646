import React from 'react'

import Sidebar from 'src/layouts/Sidebar'
import Navbar from 'src/layouts/Navbar'
import Container from 'src/layouts/Container'
import Footer from 'src/layouts/Footer'

interface IProps {
  hasNavbar?: boolean
  hasSidebar?: boolean
  hasFooter?: boolean
  children: React.ReactNode
}

const App = (props: IProps) => {
  return (
    <>
      {props.hasNavbar && <Navbar />}
      {props.hasSidebar && <Sidebar />}
      <Container>{props.children}</Container>
      {props.hasFooter && <Footer />}
    </>
  )
}

export default App
