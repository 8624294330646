import { Divider, Space } from 'antd'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'

import { QButton } from 'src/shared/components'

import { HeaderSectionStyled } from './styles'

type Direction = 'left' | 'right'

export interface HeaderSectionChangeEvent {
  direction: 'left' | 'right'
}

interface HeaderSectionProps {
  title: string
  actions?: React.ReactNode
  onChange?: (event: HeaderSectionChangeEvent) => void
  disabledLeft?: boolean
  disabledRight?: boolean
  showArrows?: boolean
}

export const HeaderSection: React.FC<HeaderSectionProps> = ({
  title,
  onChange,
  actions,
  disabledLeft,
  disabledRight,
  showArrows = true,
}) => {
  const handleChange = (direction: Direction) => {
    onChange?.({ direction })
  }

  return (
    <HeaderSectionStyled>
      <h2 className="HeaderSection__title">{title}</h2>
      <Space>
        {showArrows && (
          <>
            <QButton
              className="arrow"
              equip="primary"
              isHovered
              type="default"
              icon={<ArrowLeftOutlined />}
              onClick={() => handleChange('left')}
              disabled={disabledLeft}
            />
            <QButton
              className="arrow"
              equip="primary"
              isHovered
              type="default"
              icon={<ArrowRightOutlined />}
              onClick={() => handleChange('right')}
              disabled={disabledRight}
            />
          </>
        )}
        {actions && (
          <>
            <Divider type="vertical" />
            {actions}
          </>
        )}
      </Space>
    </HeaderSectionStyled>
  )
}
