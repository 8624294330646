import sizes from 'src/styles/theme/sizes'
import styled, { css } from 'styled-components'

export const MenuMobileStyled = styled.div`
  list-style: none;
  display: block;

  @media screen and (min-width: ${sizes.md}) {
    display: none;
  }
`

const BaseMobileItemsStyles = css`
  padding: 0.62rem;
  display: block;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.62rem;
  cursor: pointer;
  a {
    display: block;
    width: 100%;
    color: #000;
  }
`

export const MenuMobileItemStyled = styled.li`
  ${BaseMobileItemsStyles};
`

export const MenuMobileSubitemStyled = styled.li`
  ${BaseMobileItemsStyles};
`

export const BackButtonWrapStyled = styled.div`
  padding: 0.5rem 0;
  margin-bottom: 0.5rem;
  .ant-btn {
    color: #1890ff;
    padding: 0;
  }
`

export const MenuSubItemWrapperStyled = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow: auto;
  height: calc(100dvh - 208px);
  .section-wrapper {
    margin-bottom: 10px;
  }
  .item-label {
    font-weight: bold;
    color: #000;
  }
  .subitem-label {
    color: #000;
  }

  .subitem-label {
    padding: 0.5rem;
  }
  .item-label {
    padding: 0.5rem 0;
    width: 100%;
    height: 100%;
  }
  .item,
  .subitem {
    margin: 0;
    cursor: pointer;
    &:hover {
      background: #d9d9d9;
    }
  }

  @media screen and (max-width: ${sizes.md}) {
    .subitem-label,
    .item-label {
      display: block;
    }
  }
`
