import { FC } from 'react'
import { NavLink } from 'react-router-dom'

import { AdCardStyled } from './styles'

interface AdsProps {
  type: 'equip' | 'product'
}

const equip = {
  large: {
    url: '/images/home/equip-large.png',
    alt: 'Materiales de construcción de calidad con Equip',
  },
  medium: [
    { url: '/images/home/equip-medium-1.png', alt: 'Construyamos algo mejor' },
    {
      url: '/images/home/equip-medium-2.png',
      alt: 'Contamos con cascos de seguridad para tu obra',
    },
  ],
}
const product = {
  large: {
    url: '/images/home/product-large.png',
    alt: 'All products banner',
    link: '/products',
  },
  medium: [
    {
      url: '/images/home/product-medium-1.png',
      alt: 'CABLE THW-90 8 AWG 750 V.',
      link: '/products?q=Cable thw-90 8 AWG 750 V',
    },
    {
      url: '/images/home/product-medium-2.png',
      alt: 'CEMENTO SOL',
      link: '/products?q=Cemento sol',
    },
  ],
  small: [
    {
      url: '/images/im0.png',
      alt: 'ABRAZADERA DE DERIVACIÓN REFORZADA PP ROSCA HEMBRA NPT ALFA',
      link: '/products?q=Abrazadera de derivación reforzada pp rosca hembra npt alfa',
    },
    {
      url: '/images/im1.png',
      alt: 'BOTÍN ALPINISTA',
      link: '/products?q=Botín alpinista',
    },
    {
      url: '/images/im2.png',
      alt: 'CERAMICO BOSTON PLATA',
      link: '/products?q=Ceramico boston plata',
    },
  ],
}
export const Ads: FC<AdsProps> = ({ type }) => {
  return (
    <AdCardStyled>
      {type === 'equip' ? (
        <>
          <div className="box">
            <img src={equip.large.url} alt={equip.large.alt} />
          </div>
          <div className="box-grid-2">
            {equip.medium.map((img) => (
              <img key={img.url} src={img.url} alt={img.alt} />
            ))}
          </div>
        </>
      ) : (
        <>
          {/*<div className="box-grid-2">
            {product.medium.map((img) => (
              <NavLink key={img.url} to={img.link}>
                <img src={img.url} alt={img.alt} />
              </NavLink>
            ))}
          </div>*/}
          <div className="box-grid-3">
            {product.small.map((img) => (
              <NavLink key={img.url} to={img.link}>
                <img src={img.url} alt={img.alt} />
              </NavLink>
            ))}
          </div>
          <div className="box mb">
            <img src={product.large.url} alt={product.large.alt} />
          </div>
        </>
      )}
    </AdCardStyled>
  )
}
