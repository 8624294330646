import { gql } from '@apollo/client'

export const mutationAddProject = gql`
  mutation addProject($input: InputAddProject!) {
    addProject(input: $input) {
      _id
      name
      address
      addressFormatted
    }
  }
`
