import { FC } from 'react'
import { Image } from 'antd'

import { Product } from 'src/interfaces/ecommerce'

import { ItemStyled } from './styles'

interface ProductItemProps {
  product: Product
  size?: 'default' | 'large'
  footer?: JSX.Element
}

export const ProductItem: FC<ProductItemProps> = ({ size = 'default', product, footer }) => {
  return (
    <ItemStyled>
      <div className="image">
        <Image
          preview={false}
          src={product.imageUrl}
          width={size === 'large' ? 150 : 100}
          alt={product.name}
          fallback={'/images/no-image.png'}
        />
      </div>
      <div className="product-info">
        <p className="title">{product.name}</p>
        <p className="subtitle">Marca: {product.brand}</p>
        {product.qty ? <p className="subtitle">Cantidad: {product.qty}</p> : null}
        {footer ? <div className="product-footer">{footer}</div> : null}
      </div>
    </ItemStyled>
  )
}
