import { gql } from '@apollo/client'

export const mutationAddRequirement = gql`
  mutation addRequirement($input: InputAddRequirement) {
    addRequirement(input: $input) {
      _id
      code
      number
      accountId
      origin
      status
      createdAt
      updatedAt
    }
  }
`

export const mutationAddProducts = gql`
  mutation addProducts($input: InputAddProducts) {
    addProducts(input: $input) {
      _id
      index
      name
      brand
      unit
      quantity
      requirementId
      createdAt
      updatedAt
    }
  }
`
