import { useRef } from 'react'
import { CarouselRef } from 'antd/es/carousel'
import { Grid } from 'antd'

import { Benefit } from 'src/interfaces/structure'

import { QCarousel } from 'src/shared/components'

import { HeaderSection, HeaderSectionChangeEvent } from '../../components'

import { BenefitsStyle } from './styles'
import { numberSizes } from 'src/styles/theme/sizes'

interface IProps {
  benefits: Benefit[]
}

const { useBreakpoint } = Grid

export const Benefits = (props: IProps) => {
  const carouselRef = useRef<CarouselRef>(null)
  const { lg } = useBreakpoint()

  return (
    <BenefitsStyle>
      <HeaderSection
        onChange={(event: HeaderSectionChangeEvent) => {
          if (event.direction === 'left') carouselRef.current?.prev()
          else carouselRef.current?.next()
        }}
        title="Beneficios para constructoras"
        showArrows={!lg}
      />
      <br />
      <QCarousel
        items={props.benefits}
        ref={carouselRef}
        options={{
          perPage: 4,
          mediaQuery: 'min',
          breakpoints: {
            [numberSizes.lg]: {
              perPage: 4,
            },
            [numberSizes.md]: {
              perPage: 3,
            },
            [numberSizes.sm]: {
              perPage: 2,
            },
            [numberSizes.xs]: {
              perPage: 1,
            },
          },
        }}
        renderItem={(benefit: Benefit) => (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '3fr 7fr',
              backgroundColor: 'white',
              borderRadius: 8,
              height: 130,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                backgroundColor: '#FA8C16',
                borderTopLeftRadius: 8,
                borderBottomLeftRadius: 8,
              }}
            >
              <img src={benefit.iconUrl} />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'start',
                flexDirection: 'column',
                gap: 4,
                padding: '20px 10px',
              }}
            >
              <span style={{ fontWeight: 'bold', fontSize: 16 }}>{benefit.title}</span>
              <span style={{ fontSize: 14 }}>{benefit.description}</span>
            </div>
          </div>
        )}
      />
    </BenefitsStyle>
  )
}
