import { type PropsWithChildren, useEffect, useState } from 'react'
import { obtainAccount } from 'src/api/graphql'
import { SetLocalStorage, createSafeContext, useLocalStorage } from 'src/hooks'
import { LocalStorageEntities } from 'src/interfaces/types/app'
import type { IAccount } from 'src/shared/helpers/storage'
import type { IUser } from './types'

type UserState = IUser | null
type StaragedAccount = IAccount | null

interface AuthProviderResolvers {
  user: UserState
  clearAccount: () => void
  setStoragedAccount: SetLocalStorage<StaragedAccount>
}

const [useAuth, Provider] = createSafeContext<AuthProviderResolvers>()

export { useAuth }

export default function AuthProvider({ children }: PropsWithChildren) {
  const [user, setUser] = useState<UserState>(null)
  const [storagedAccount, setStoragedAccount] = useLocalStorage<StaragedAccount>(
    LocalStorageEntities.account,
    null,
    {
      sync: true,
    }
  )
  const { data, loading } = obtainAccount({ id: storagedAccount?.id }, { skip: !storagedAccount })

  const clearAccount = () => {
    setStoragedAccount(null)
  }

  useEffect(() => {
    if (!loading && data) {
      setUser(data?.obtainAccount)
    }
  }, [data, loading])

  return (
    <Provider
      value={{
        user,
        clearAccount,
        setStoragedAccount,
      }}
    >
      {children}
    </Provider>
  )
}
