import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Spin, Result } from 'antd'

import { useLoadingInit } from 'src/hooks/useLoadingInit'

import { CompleteRegBuyer } from '../components/CompleteRegBuyer'

import { validateHash } from '../helpers/validations'

const Complete = () => {
  const { accountId } = useParams()
  const { loadingInit } = useLoadingInit()
  const [hasError, setHasError] = useState<boolean>(false)

  useEffect(() => {
    if (accountId) {
      if (validateHash(accountId)) console.log(accountId)
      else setHasError(true)
    }
  }, [accountId])

  if (loadingInit) {
    return (
      <div className="window-center">
        <Spin size="large" tip="¡Bienvenido a Equip!" />
      </div>
    )
  }

  return hasError ? (
    <Result
      style={{ padding: 80 }}
      status="error"
      title={'Link de completado de registro incorrecto'}
    />
  ) : (
    <CompleteRegBuyer hash={accountId ?? ''} />
  )
}

export default Complete
