class Dims {
  marginx: string = '20px'
  paddingx: string = '10px'
  marginy: string = '10px'
  paddingy: string = '20px'
  paddingx_md: string = '100px'
  paddingx_lg: string = '200px'
}

export default new Dims()
