import { Card } from 'antd'

import styled from 'styled-components'

import sizes from 'src/styles/theme/sizes'

export const StoreStyled = styled.div`
  padding: 10px;
  .breadcrumb {
    display: none;
  }
  @media screen and (min-width: ${sizes.sm}) {
    padding: 40px;
    .breadcrumb {
      display: unset;
    }
  }

  img {
    height: 240px;
  }
  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 0 40px;
    .filter-button {
      margin-top: 20px;
      @media screen and (min-width: ${sizes.sm}) {
        display: none;
      }
    }
    h2 {
      font-size: 48px;
      font-weight: 700;
      margin: 0;
    }
    p {
      margin: 10px;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      margin: 0;
      color: #595959;
    }
  }
  .filter-products-wrapper {
    display: grid;
    grid-template-columns: 200px 1fr;
    column-gap: 20px;

    @media screen and (max-width: ${sizes.sm}) {
      display: flex;
      justify-content: center;

      .dynamic-filters {
        display: none !important;
      }
    }
  }

  .list-products {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
  }

  .footer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
`

export const ProductStyled = styled(Card)`
  margin-top: 10px;
  margin-bottom: 10px;

  .content {
    display: flex;
    align-items: center;

    .icon {
      font-size: 30px;
      color: #4d4d4d;

      &:hover {
        color: red;
        font-size: 32px;
      }
    }
  }
`
