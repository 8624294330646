import styled, { StyledComponent, css } from 'styled-components'

export const PanelStyled: StyledComponent<'div', any, { isOpen: boolean }> = styled.div`
  visibility: none;
  pointer-events: none;
  max-height: 28.4rem;
  position: fixed;
  left: 50%;
  transform: translate(-50%) translateY(-100%);
  z-index: 1000;
  transition: all 0.3s ease-in-out;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  ${({ isOpen }: any) =>
    isOpen &&
    css`
      visibility: visible;
      pointer-events: initial;
      transform: translate(-50%) translateY(0);
    `}
  ${({ isOpen }: any) =>
    !isOpen &&
    css`
      visibility: hidden;
      pointer-events: none;
    `}
`
