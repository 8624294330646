import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Drawer, Pagination, Breadcrumb } from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { FilterOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons'

import { useCart, useProducts, useDialog } from 'src/shared/hooks'

import { PageInfo } from 'src/interfaces/backend'
import { Product } from 'src/interfaces/ecommerce'

import { findBrand } from 'src/api/graphql'

import { Filters, Empty, QButton, QCard, QLoading } from 'src/shared/components'
import { ImageCardStyled } from 'src/pages/Products/ProductsPage/styles'
import { Img } from 'src/shared/components/Card/Image'
import { Banner } from '../../components'

import { StoreStyled } from '../../StorePage/styles'

const initialValues: PageInfo = {
  currentPage: 1,
  hasNextPage: false,
  hasPreviousPage: false,
  perPage: 20,
  total: 163,
}

export interface FilterState {
  categories: CheckboxValueType[]
  brands: CheckboxValueType[]
}

interface IProps {
  brandSlug: string
  q: string | null
  categories: string[] | undefined
}

const StoreDetails: React.FC<IProps> = ({ brandSlug, q, categories }) => {
  const [brand, setBrand] = useState<any>()
  const [cats, setCats] = useState<any[]>()
  const [isInit, setInit] = useState<boolean>(false)
  const [pageInfo, setPageInfo] = useState<PageInfo>(initialValues)
  const { open, handleOpen, handleClose } = useDialog()
  const { addProductToCart } = useCart()

  const qBrand = findBrand({ name: brandSlug ?? '' })
  const qProducts = useProducts({
    pagination: { page: pageInfo.currentPage, perPage: pageInfo.perPage },
    filters: { categories, priorBrand: brandSlug, q },
  })

  const add = (product: Product) => addProductToCart(product)
  const handlePage = (page: number) => setPageInfo({ ...pageInfo, currentPage: page })
  const handleData = (br: any) => {
    const cats =
      br.categoriesEntity?.map((cat: any) => {
        if (cat.name === '__others') return { key: 'Otros', title: 'Otros' }
        return {
          key: cat.name,
          title: cat?.name,
          children: cat?.subcategories?.map((sub: any) => ({ key: sub.name, title: sub.name })),
        }
      }) ?? []
    setCats([...cats])
    setBrand(br)
  }

  useEffect(() => {
    if (q) setPageInfo((prev) => ({ ...prev, currentPage: 1 }))
  }, [q])

  useEffect(() => {
    qBrand
      .refetch()
      .then((r) => {
        const br = r.data.findBrand
        if (br) handleData(br)
      })
      .catch((err) => console.error('err', err))
  }, [brandSlug])

  useEffect(() => {
    if (!qBrand.loading && qBrand.data) {
      if (isInit) return
      setInit(true)
      const br = qBrand.data.findBrand
      if (br) handleData(br)
    }
  }, [qBrand.loading, qBrand.data])

  useEffect(() => {
    if (!qProducts.loading) setPageInfo({ ...qProducts.paginated })
  }, [!qProducts.loading])

  return (
    <StoreStyled>
      <Breadcrumb
        className="breadcrumb"
        items={[
          { href: '/', title: 'Inicio' },
          { href: `/providers`, title: 'Tienda' },
          { title: brand?.name },
        ]}
        style={{ marginBottom: '20px' }}
      />
      <Banner
        name={brand?.name}
        logoUrl={brand?.url}
        bannerUrl={window.innerWidth < 600 ? brand?.bannerMobileUrl : brand?.bannerUrl}
        loading={qBrand.loading}
      />
      <div className="header">
        <h2>{brand?.name}</h2>
        {qProducts.paginated?.total && <p>{qProducts.paginated?.total} productos</p>}
        <Button className="filter-button" icon={<FilterOutlined />} onClick={handleOpen}>
          Filtros
        </Button>
      </div>
      <div className="filter-products-wrapper">
        <div className="dynamic-filters">
          <Filters
            path={`/providers/${brandSlug}`}
            categories={cats}
            onFiltered={() => setPageInfo((prev) => ({ ...prev, currentPage: 1 }))}
          />
        </div>

        <div className="body">
          <QLoading loading={qProducts.loading}>
            <div className="list-products">
              {qProducts.products.map((product: Product, index: number) => (
                <QCard
                  key={product.id + index}
                  style={{ padding: 0 }}
                  isRounded
                  isBordered
                  hasShadow
                >
                  <Link to={`/products/${product.skuEquip}`}>
                    <ImageCardStyled>
                      <Img key={`image-${index}`} src={product.imageUrl} alt={`${product?.name}`} />
                      {product.imageUrl1 && (
                        <img
                          key={`image1-${index}`}
                          className="second-img"
                          src={product.imageUrl1}
                          alt={`${product?.name}`}
                        />
                      )}
                    </ImageCardStyled>
                  </Link>
                  <QCard.Body style={{ padding: '10px', borderTop: '1px solid #DEE2E6' }}>
                    <Link to={`/products/${product.skuEquip}`}>
                      <p className="product-name">{`${product.name}`}</p>
                    </Link>
                    <QButton
                      block
                      type="link"
                      size="large"
                      style={{
                        fontSize: 16,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      icon={<PlusOutlined className="icon" />}
                      onClick={() => add(product)}
                    >
                      Agregar
                    </QButton>
                  </QCard.Body>
                </QCard>
              ))}
            </div>
            {pageInfo.currentPage > 1 || qProducts.products.length >= 20 ? (
              <div className="footer">
                <Pagination
                  current={pageInfo.currentPage}
                  pageSize={pageInfo.perPage}
                  total={pageInfo.total}
                  showSizeChanger={false}
                  onChange={handlePage}
                />
              </div>
            ) : null}
            <Empty
              totalPerPage={qProducts.products.length}
              indexCurrentPage={pageInfo.currentPage}
              total={pageInfo.total}
            />
          </QLoading>
        </div>
      </div>

      <Drawer
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Link className="logo-desktop" to="/">
              <img src="/logos/logo.svg" height="48" alt="Equip" />
            </Link>
            <CloseOutlined style={{ fontSize: 26 }} onClick={handleClose} />
          </div>
        }
        placement="left"
        closable={false}
        onClose={handleClose}
        open={open}
        key="left"
      >
        <Filters
          path={`/providers/${brandSlug}`}
          categories={cats}
          onFiltered={() => setPageInfo((prev) => ({ ...prev, currentPage: 1 }))}
        />
      </Drawer>
    </StoreStyled>
  )
}

export default StoreDetails
