import { constants } from './constants'

import { FooterStyled, NavFooter } from './styles'

const Footer = () => {
  return (
    <FooterStyled>
      <div className="main-content">
        <div>
          <img src="/logos/logo.svg" height="52" />
          <p className="desc">Somos el marketplace B2B de la construcción en Perú</p>
          <p className="small-gray">Razón Social: ENDUSTRIA S.A.C.</p>
          <p className="small-gray">RUC: 20605100920</p>
          {/* <div className="grid-space">
            <QButton equip="primary">
              <a href={`${config.platform.landing}/register`}>Regístrate</a>
            </QButton>

            <QButton equip="primary" type="default">
              <a href={`${config.platform.auth}`}>Iniciar Sesión</a>
            </QButton>
          </div> */}
        </div>
        <NavFooter>
          <h2 className="nav-title">Servicio al cliente</h2>
          <ul className="nav-list">
            {constants.clientServices.map(({ url, name }) => (
              <li key={name}>
                <a href={url} target="_blank">
                  {name}
                </a>
              </li>
            ))}
          </ul>
        </NavFooter>
        <NavFooter>
          <h2 className="nav-title">Contáctanos</h2>
          <ul className="nav-list">
            {constants.contact.map(({ url, name }) => (
              <li key={name}>
                <a href={url} target="_blank">
                  {name}
                </a>
              </li>
            ))}
          </ul>
        </NavFooter>
      </div>
      <NavFooter>
        <ul className="nav-list nav-list--social">
          {constants.social.map(({ url, icon }) => (
            <li key={url}>
              <a href={url}>{icon}</a>
            </li>
          ))}
        </ul>
      </NavFooter>
      <p className="footer-copy">© EquipConstruye | Todos los Derechos Reservados.</p>
    </FooterStyled>
  )
}

export default Footer
