import { notification } from 'antd'
import { ArgsProps, IconType } from 'antd/lib/notification/interface'

interface IQnotify extends ArgsProps {
  type: IconType
}

const Notify = (props: IQnotify) => {
  const { type, message, description, placement = 'topRight', ...others } = props

  return notification[type]({
    message,
    description,
    placement,
    ...others,
  })
}

export default Notify
