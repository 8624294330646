import { useEffect, useRef, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'

import { Brand } from 'src/interfaces/ecommerce'

import { listBrands } from 'src/api/graphql'

import { QButton, QCard, QCarousel } from 'src/shared/components'

import { HeaderSection, HeaderSectionChangeEvent } from '../../components'

import { BrandsStyle } from './styles'
import { CarouselRef } from 'antd/es/carousel'

interface IProps {
  names: string[]
}

export const Brands = (props: IProps) => {
  const { data, loading } = listBrands(
    { page: 1, perPage: 100 },
    { isActive: true, names: props.names }
  )
  const [brands, setBrands] = useState<Brand[]>([])

  const carouselRef = useRef<CarouselRef>(null)

  useEffect(() => {
    if (data) {
      const brands: any[] = []
      props.names.forEach((brName: string) => {
        const found = data.listBrands.brands.find((brand: any) => brand.name == brName)
        if (found) brands.push(found)
      })
      setBrands([...brands])
    }
  }, [loading, data])

  return (
    <BrandsStyle>
      <HeaderSection
        onChange={(event: HeaderSectionChangeEvent) => {
          if (event.direction === 'left') {
            carouselRef.current?.prev()
          } else {
            carouselRef.current?.next()
          }
        }}
        title="Marcas"
        actions={
          <NavLink to="/providers">
            <QButton equip="primary" type="default" isHovered>
              Ver más
            </QButton>
          </NavLink>
        }
      />
      <br />
      <QCarousel
        items={brands}
        ref={carouselRef}
        renderItem={(brand) => (
          <Link
            to={brand.hasStore ? `/providers/${brand.name}` : `/products?brands=${brand.name}`}
            style={{ textDecoration: 'unset', display: 'block', width: '100%' }}
          >
            <QCard style={{ height: '114px', padding: '20px', width: '100%' }} hasShadow isRounded>
              <QCard.Image
                style={{ height: '100%', width: '100%' }}
                images={[{ alt: brand.name, url: brand.url || '' }]}
                objectFit="contain"
              />
            </QCard>
            <div className="brand-name">
              <span>{brand.name}</span>
            </div>
          </Link>
        )}
      />
    </BrandsStyle>
  )
}
