import sizes from 'src/styles/theme/sizes'
import styled from 'styled-components'

export const AdCardStyled = styled.div`
  display: flex;
  flex-direction: column;
  .box-grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .box-grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  &,
  .box-grid-3,
  .box-grid-2 {
    gap: 1.25rem;
  }
  .box {
    width: 100%;
    height: 100%;
  }
  img {
    max-height: 420px;
    width: 100%;
    border-radius: 1rem;
    object-fit: contain;
    box-shadow: 0px 20px 60px 0px #195aff26;
  }
  .mb {
    margin-bottom: 65px;
  }
  @media screen and (min-width: ${sizes.lg}) {
    &,
    .box-grid-3,
    .box-grid-2 {
      gap: 2.5rem;
    }
  }
`
