import { useEffect } from 'react'
import { PanelStyled } from './styles'

export interface PanelProps {
  isOpen: boolean
  onClose?: () => void
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

export const Panel: React.FC<PanelProps> = ({ isOpen, onClose, children, style }) => {
  const content = (
    <PanelStyled isOpen={isOpen} onClick={onClose} style={style}>
      {children}
    </PanelStyled>
  )

  return <>{isOpen ? content : null}</>
}
