import styled from 'styled-components'

const maxWidth = '1000px'

export const CartFooterStyled = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  @media screen and (max-width: ${maxWidth}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const CartStyled = styled.div`
  padding: 40px 15px;
  h2 {
    font-size: 34px;
    font-weight: bold;
    line-height: 45px;
    max-width: ${maxWidth};
    margin: 0 auto 40px;
  }
`
export const CardStyled = styled.div`
  box-shadow: 0px 20px 60px rgba(25, 90, 255, 0.15);
  border-radius: 16px;
  background: #fff;
  max-width: ${maxWidth};
  margin: auto;
  .cart-footer {
    padding: 20px;
    display: flex;
    justify-content: center;
    .ant-btn {
      width: 300px;
    }
  }
`
