import * as yup from 'yup'

export const requirementSchema = yup.object().shape({
  fullname: yup.string().required('Este campo es requerido'),
  email: yup.string().email('El email no es válido.').required('Este campo es requerido'),
  // ruc: yup.string().length(11, 'RUC inválido').required('Este campo es requerido'),
  ruc: yup.string().required('Este campo es requerido'),
  // cellphone: yup.string().length(9, 'RUC inválido').required('Este campo es requerido'),
  cellphone: yup.string().required('Este campo es requerido'),
  terms: yup.boolean().oneOf([true], 'Debes aceptar los términos y condiciones'),
})
