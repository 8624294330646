import { gql } from '@apollo/client'

export const FILTER_BRANDS = gql`
  query filterBrands($input: FilterBrands!) {
    filterBrands(input: $input) {
      _id
      logoUrl
      hasStore
      name
    }
  }
`
