import { gql } from '@apollo/client'

export const queryListDistricts = gql`
  query listDistricts($paginated: Paginated, $filter: FilterListDistrict) {
    listDistricts(paginated: $paginated, filter: $filter) {
      pageInfo {
        total
        currentPage
        perPage
        hasPreviousPage
        hasNextPage
      }
      districts {
        _id
        name
        code
        department {
          name
          code
        }
      }
    }
  }
`
