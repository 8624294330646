import { configureStore } from '@reduxjs/toolkit'

import { commonsApi } from './api'
import { cartSlice } from './states'

const store = configureStore({
  reducer: {
    cart: cartSlice.reducer,
    [commonsApi.reducerPath]: commonsApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(commonsApi.middleware),
})

export type AppStore = ReturnType<typeof store.getState>

export default store
