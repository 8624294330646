import React from 'react'
import { MenuPanelStyled, PanelContentWrapper } from './styles'

interface CategoryPanelProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title' | 'content'> {
  isOpen: boolean
  style: React.CSSProperties
  title: string
  content: React.ReactNode
}

export const MenuPanel: React.FC<CategoryPanelProps> = ({
  isOpen,
  style,
  title,
  content,
  ...rest
}) => {
  return (
    <MenuPanelStyled isOpen={isOpen} style={style} {...rest}>
      <PanelContentWrapper>
        <div className="part-left">{title}</div>
        <ul className="part-right">{content}</ul>
      </PanelContentWrapper>
    </MenuPanelStyled>
  )
}
