import { useEffect, useState } from 'react'
import { Button, CheckboxOptionType } from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'

import { FilterKeyEnum, useDialog, useFilters } from 'src/shared/hooks'
import format from 'src/shared/helpers/format'

import { FiltersGroupStyled, CheckboxGroupStyled, TreeStyled, FiltersStyled } from './styles'
import { moveCheckedsToStart, moveCheckedsToStartCategories } from './utils'
import type { DataNode } from 'antd/es/tree'

interface FiltersProps {
  hasSearch?: boolean
  categories?: FilterCategory[]
  brands?: FilterBrand[]
  path?: string
  onFiltered: () => void
}
export type FilterCategory = DataNode & { brands?: string[] }
interface FilterBrand {
  name: string
  categories?: string[]
}

const Filters = (props: FiltersProps) => {
  const [filterBrands, setFilterBrands] = useState<CheckboxOptionType[]>([])
  const [filterCategories, setFilterCategories] = useState<FilterCategory[]>([])
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>()
  const { open: openCat, handleOpen: handleOpenCat } = useDialog()
  const { open: openBrand, handleOpen: handleOpenBrand } = useDialog()
  const { getQueryParams, applyNavigateFilter } = useFilters()
  const queryParams = getQueryParams([
    FilterKeyEnum.q,
    FilterKeyEnum.categories,
    FilterKeyEnum.brands,
  ])
  const { categories: categoriesParams, brands: brandsParams, q } = queryParams

  const handleCheck = (
    checkedValue: CheckboxValueType[],
    name: keyof typeof FilterKeyEnum,
    qSearched: string
  ) => {
    applyNavigateFilter(props?.path ?? '/products', [
      [FilterKeyEnum.q, qSearched],
      [FilterKeyEnum.categories, name === 'categories' ? checkedValue.join(',') : categoriesParams],
      [FilterKeyEnum.brands, name === 'brands' ? checkedValue.join(',') : brandsParams],
    ])
  }

  useEffect(() => {
    if (categoriesParams) {
      const filterBrands = props.brands?.filter((b: FilterBrand) => {
        return b.categories?.some((c) =>
          categoriesParams?.toLowerCase()?.split(',')?.includes(c?.toLowerCase())
        )
      })

      setFilterBrands(format.toCheckOptions(filterBrands))
      props.onFiltered()
    }
  }, [categoriesParams, props.brands])

  useEffect(() => {
    if (brandsParams) {
      const catsByBrand = props.brands
        ?.filter((b: FilterBrand) => {
          return (
            b.categories && brandsParams?.toLowerCase()?.split(',')?.includes(b.name?.toLowerCase())
          )
        })
        .flatMap((b) => b.categories)
      const filterCategories = props.categories?.filter((c) => {
        return catsByBrand?.some((cat) => cat?.toLowerCase() === c.key.toString().toLowerCase())
      })
      setFilterCategories(filterCategories ?? [])
      props.onFiltered()
    }
  }, [brandsParams, props.categories])

  useEffect(() => {
    if (openBrand) setFilterBrands(format.toCheckOptions(props.brands))
    if (openCat) setFilterCategories(props.categories ?? [])
  }, [openBrand, openCat])

  useEffect(() => {
    if (q) {
      handleCheck([], 'brands', q)
      handleCheck([], 'categories', q)
    }
  }, [q])

  const brandsFilterChecked = brandsParams?.length ? format.splitCommaNoSpace(brandsParams) : []

  const categoriesFilterChecked = categoriesParams ? format.splitCommaNoSpace(categoriesParams) : []

  const onCheck = (value: string[] & any, info: any) => {
    const checked = [...(value?.checked ?? value)]
    handleCheck(checked, 'categories', '')
    setExpandedKeys([...checked, ...info?.halfCheckedKeys])
  }

  const onExpand = (expandedKeysValue: React.Key[]) => {
    setExpandedKeys(expandedKeysValue)
  }

  return (
    <FiltersStyled>
      {props.categories ? (
        <section>
          <h4>{props.hasSearch ? 'Categorías adicionales' : 'Categorías'}</h4>
          <FiltersGroupStyled open={openCat} height={308}>
            <TreeStyled
              checkable
              selectable={false}
              onExpand={onExpand}
              expandedKeys={expandedKeys}
              checkedKeys={categoriesFilterChecked}
              onCheck={onCheck}
              treeData={[
                ...moveCheckedsToStartCategories(
                  brandsParams ? filterCategories : props.categories,
                  format.splitCommaNoSpace(categoriesParams ?? '')
                ),
              ]}
            />
          </FiltersGroupStyled>
          {props.categories.length > 10 && !openCat ? (
            <Button type="link" onClick={() => handleOpenCat()}>
              Mostrar más
            </Button>
          ) : null}
        </section>
      ) : null}

      {props.brands ? (
        <section>
          <h4>Marcas</h4>
          <FiltersGroupStyled open={openBrand} height={484}>
            <CheckboxGroupStyled
              defaultValue={brandsFilterChecked}
              value={brandsFilterChecked}
              options={[
                ...moveCheckedsToStart(
                  categoriesParams ? filterBrands : format.toCheckOptions(props.brands),
                  format.splitCommaNoSpace(brandsParams ?? '')
                ),
              ]}
              onChange={(values) => {
                handleCheck(values, 'brands', '')
              }}
            />
          </FiltersGroupStyled>
          {!openBrand ? (
            <Button type="link" onClick={() => handleOpenBrand()}>
              Mostrar más
            </Button>
          ) : null}
        </section>
      ) : null}
    </FiltersStyled>
  )
}

export default Filters
