import { FC } from 'react'
import { Empty as AntEmpty } from 'antd'

import { EmptyStyled } from './styles'

import config from 'src/config/app'

interface EmptyProps {
  totalPerPage: number
  indexCurrentPage: number
  total: number
}

const perPage = 20

const Empty: FC<EmptyProps> = ({ totalPerPage, indexCurrentPage, total }) => {
  const isEmpty = totalPerPage === 0
  const showInLastResults = total < perPage * indexCurrentPage
  return (
    <>
      {isEmpty ? (
        <AntEmpty
          style={{ marginBlock: 90 }}
          image={AntEmpty.PRESENTED_IMAGE_SIMPLE}
          description="Sin resultados"
        />
      ) : null}
      {isEmpty || showInLastResults ? (
        <EmptyStyled>
          <a href={config.platform.landing}>
            <img src="/images/no-result-products.jpg" alt="No results" />
          </a>
        </EmptyStyled>
      ) : null}
    </>
  )
}
export default Empty
