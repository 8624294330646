import { FacebookFilled, InstagramFilled, LinkedinFilled } from '@ant-design/icons'

import config from 'src/config/app'

export const constants = {
  clientServices: [
    { url: `/policies/privacy`, name: 'Políticas de privacidad' },
    { url: `/policies/terms`, name: 'Términos y Condiciones' },
  ],
  contact: [
    {
      url: `tel:${config.web.cellNumber?.replace(/ /g, '')}`,
      name: `Teléfono: ${config.web.cellNumber}`,
    },
    {
      url: `mailto:${config.web.email}`,
      name: `Email: ${config.web.email}`,
    },
  ],
  social: [
    {
      url: 'https://www.facebook.com/Equip-Industry-Latam-504625556961748/',
      icon: <FacebookFilled style={{ fontSize: 24 }} />,
    },
    {
      url: 'https://www.instagram.com/equipconstruye/',
      icon: <InstagramFilled style={{ fontSize: 24 }} />,
    },
    {
      url: 'https://www.linkedin.com/company/equipindustry/',
      icon: <LinkedinFilled style={{ fontSize: 24 }} />,
    },
  ],
}
