import { SidebarStyle } from './styles'

interface Item {
  id: string
  title: string
}

const items: Item[] = [
  { id: '1', title: 'Categorías' },
  { id: '2', title: 'Marca' },
  { id: '3', title: 'Proveedor' },
]

const Sidebar = () => {
  return (
    <SidebarStyle>
      <ul>
        {items.map((item: Item) => (
          <li key={item.id}>{item.title}</li>
        ))}
      </ul>
    </SidebarStyle>
  )
}

export default Sidebar
