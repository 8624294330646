import styled from 'styled-components'

import colors from 'src/styles/theme/colors'

import { ImageProps } from './index'

const gridStyles = `
  display: grid;
  grid-template-columns: 1fr 1fr; 
  img:nth-child(1) {
    grid-area: image1;
    object-fit: contain;
    border-bottom: 1px solid ${colors.borderGray};
    border-right: 1px solid ${colors.borderGray};
  }
  img:nth-child(2) {
    grid-area: image2;
    border-bottom: 1px solid ${colors.borderGray};
  }
  img:nth-child(3) {
    grid-area: image3;
    border-bottom: 1px solid ${colors.borderGray};
  }
  grid-template-areas: 'image1 image2' 'image1 image3';
`

export const ImageStyle = styled.div<Omit<ImageProps, 'images'>>`
  display: block;
  text-align: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    opacity: 0;
    transition: 1s;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    ${({ objectFit }) => (objectFit ? `object-fit: ${objectFit}` : '')};
  }
  .fallback {
    max-height: 180px;
    max-width: 180px;
  }
  img.loaded {
    opacity: 1;
  }
  ${({ isGrid }) => (isGrid ? gridStyles : '')}
`
