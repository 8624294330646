import { Image } from 'antd'
import sizes from 'src/styles/theme/sizes'
import styled from 'styled-components'

export const ImageStyled = styled(Image)`
  width: auto !important;
  height: 88px !important;
  object-fit: contain !important;
  @media screen and (min-width: ${sizes.xl}) {
    width: auto !important;
    height: 135px !important;
  }
  @media screen and (min-width: ${sizes.xxl}) {
    width: auto !important;
    height: 270px !important;
  }
`

export const ProviderListWrapperStyled = styled.section`
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  row-gap: 1.25rem;
  img {
    display: block;
    max-width: 100%;
  }
  @media screen and (min-width: ${sizes.lg}) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (min-width: ${sizes.xl}) {
    grid-template-columns: repeat(4, 1fr);
  }
`

export const ProviderListItemStyled = styled.div`
  background-color: #fff;
  border-radius: 0.5rem;
  overflow: hidden;
  .provider-img-wrapper {
    padding: 1.25rem;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    justify-content: center;
  }
  .provider-img {
    object-fit: contain;
  }
  .provider-content {
    padding: 1.25rem;
  }
  .provider-name {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
  }
`
