import { gql } from '@apollo/client'

export const queryGetLegal = gql`
  query getLegal($input: InputLegal) {
    getLegal(input: $input) {
      ruc
      businessName
      statusContributor
      statusResidence
      ubigeo
      businessLocation {
        typeWay
        nameWay
        codeZone
        typeZone
        number
        intern
        department
        province
        district
        address
        fullAddress
      }
      lastUpdated
    }
  }
`
