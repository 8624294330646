import '@splidejs/react-splide/css'
import { forwardRef, useImperativeHandle, useRef } from 'react'

import { Splide, SplideProps, SplideSlide } from '@splidejs/react-splide'
import { numberSizes } from 'src/styles/theme/sizes'

export interface CarouselRef {
  next: () => void
  prev: () => void
}

export interface CarouselProps<T = any> extends SplideProps {
  items: T[]
  renderItem: (item: T) => React.ReactNode
}

function Component2<T>(
  { items, renderItem, options, ...props }: CarouselProps,
  ref: React.ForwardedRef<CarouselRef>
) {
  const carouselRef = useRef<any>(null)

  useImperativeHandle(ref, () => ({
    next: () => {
      carouselRef.current?.go('+1')
    },
    prev: () => {
      carouselRef.current?.go('-1')
    },
  }))

  return (
    <Splide
      options={{
        rewind: true,
        gap: '1rem',
        perPage: 2,
        pagination: false,
        arrows: false,
        mediaQuery: 'min',
        breakpoints: {
          [numberSizes.lg]: {
            perPage: 5,
          },
          [numberSizes.md]: {
            perPage: 4,
          },
          [numberSizes.sm]: {
            perPage: 3,
          },
        },
        ...options,
      }}
      ref={carouselRef}
      {...props}
    >
      {items.map((brand, index) => (
        <SplideSlide key={brand?.id ?? index}>{renderItem(brand)}</SplideSlide>
      ))}
    </Splide>
  )
}

const Carousel = forwardRef(Component2)

export default Carousel
