import styled from 'styled-components'

import colors from 'src/styles/theme/colors'

export const BenefitsStyle = styled.div`
  padding-top: 0px;
  padding-bottom: 65px;
  padding-left: 0px;
  padding-right: 0px;
  .list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 50px 0;
  }
  .benefit-name {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 20px;
    text-transform: capitalize;
    font-weight: 500;
    color: ${colors.black};
    font-size: 18px;
  }
`
