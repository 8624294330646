import { useState, useEffect } from 'react'
import { Spin, Drawer } from 'antd'
import { Link } from 'react-router-dom'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { CloseOutlined, FilterOutlined } from '@ant-design/icons'

import { useFilters, FilterKeyEnum } from 'src/shared/hooks'

import { PageInfo } from 'src/interfaces/backend'
import { Brand } from 'src/interfaces/ecommerce'

import { useListFilters } from 'src/api/graphql'

import { Filters } from 'src/shared/components'
import { FilterCategory } from 'src/shared/components/Filters'

import { ListProducts } from './List'

import { FilterButton, StoreStyled } from './styles'

const initialValues: Partial<PageInfo> = {
  currentPage: 1,
  perPage: 20,
}

export interface FilterState {
  categories: CheckboxValueType[]
  brands: CheckboxValueType[]
}

const Products = () => {
  const [open, setOpen] = useState<boolean>(false)

  const [categories, setCategories] = useState<FilterCategory[]>([])
  const [brands, setBrands] = useState<Brand[]>([])
  const [pageInfo, setPageInfo] = useState<Partial<PageInfo>>(initialValues)

  const { getQueryParams } = useFilters()
  const queryParams = getQueryParams([FilterKeyEnum.q])
  const { q } = queryParams

  const queryFilters = useListFilters({ brands: [], categories: [], subcategories: [] })

  useEffect(() => {
    if (!queryFilters.loading && queryFilters.data) {
      const filters = queryFilters.data.filters ?? []

      const mapBrands = filters?.map((brand: any) => ({
        name: brand.brand,
        categories: brand.categories.map((cat: any) => cat.category),
      }))
      setBrands(mapBrands)

      const adaptedCategories = filters
        ?.flatMap((category: any) => category?.categories)
        ?.filter(
          (itm: any, index: number, arr: any[]) =>
            arr.findIndex((item: any) => item.category === itm.category) === index
        )
        ?.map((category: any) => ({
          key: category.category,
          title: category.category,
          children: category.subcategories
            .filter((itm: any) => itm.subcategory)
            .map((subcategory: any) => ({
              title: subcategory.subcategory,
              key: `${subcategory.subcategory}`,
            })),
        }))
        .sort((a: any, b: any) => (a.title < b.title ? -1 : 1))

      setCategories(adaptedCategories)
    }
  }, [queryFilters.loading, queryFilters.data])

  return (
    <StoreStyled>
      <div className="header" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <h2>{q ? `Resultados de: ${q}` : 'Todos los productos'}</h2>
          <p>{pageInfo?.total} resultados</p>
        </div>
        <FilterButton type="primary" onClick={() => setOpen(true)} icon={<FilterOutlined />}>
          Filtros
        </FilterButton>
      </div>
      <div className="filter-products-wrapper">
        <div className="dynamic-filters">
          <Spin spinning={queryFilters.loading || queryFilters.loading}>
            <Filters
              hasSearch={Boolean(q)}
              path="/products"
              categories={categories}
              brands={brands}
              onFiltered={() => setPageInfo((prev) => ({ ...prev, currentPage: 1 }))}
            />
          </Spin>
        </div>

        <ListProducts q={q} pageInfo={pageInfo} setPageInfo={setPageInfo} />
      </div>

      <Drawer
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Link className="logo-desktop" to="/">
              <img src="/logos/logo.svg" height="48" alt="Equip" />
            </Link>
            <CloseOutlined style={{ fontSize: 26 }} onClick={() => setOpen(false)} />
          </div>
        }
        placement="left"
        closable={false}
        onClose={() => setOpen(false)}
        open={open}
        key="left"
      >
        <Spin spinning={queryFilters.loading || queryFilters.loading}>
          <Filters
            path="/products"
            categories={categories}
            brands={brands}
            onFiltered={() => setPageInfo((prev) => ({ ...prev, currentPage: 1 }))}
          />
        </Spin>
      </Drawer>
    </StoreStyled>
  )
}

export default Products
