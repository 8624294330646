import { useEffect, useState } from 'react'

export const useLoadingInit = () => {
  const [loadingInit, setLoadingInit] = useState(true)

  useEffect(() => {
    setLoadingInit(false)
  }, [])

  return { loadingInit, isLoaded: loadingInit === false }
}
