import Body from './Body'
import Image from './Image'

import { CardStyle } from './styles'

export interface CardProps {
  children?: React.ReactNode
  hasShadow?: boolean
  isBordered?: boolean
  isRounded?: boolean
  style?: React.CSSProperties
}

const Card = ({ children, hasShadow, isRounded, ...rest }: CardProps) => {
  return (
    <CardStyle hasShadow={hasShadow} isRounded={isRounded} {...rest}>
      {children}
    </CardStyle>
  )
}

Card.Image = Image
Card.Body = Body

export default Card
