import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { FilterKeyEnum, useFilters } from 'src/shared/hooks'

import StoreDetails from '../components/Details'
import { Spin } from 'antd'

interface IFilters {
  brandName: string
  q: string | null
  categories: string[] | undefined
}

const Store = () => {
  const { getQueryParams } = useFilters()
  const { brandSlug } = useParams()
  const [filters, setFilters] = useState<IFilters>({
    brandName: '',
    q: null,
    categories: undefined,
  })

  const queryParams = getQueryParams([
    FilterKeyEnum.q,
    FilterKeyEnum.brands,
    FilterKeyEnum.categories,
  ])

  useEffect(() => {
    const { q, categories } = queryParams
    if (brandSlug) filters.brandName = brandSlug
    if (q) filters.q = q
    if (categories) {
      const cats = categories.split(',')
      if (!cats.includes('all')) filters.categories = cats
    }
    setFilters({ ...filters })
  }, [queryParams.q, queryParams.categories])

  if (!filters.brandName) return <Spin />

  return (
    <StoreDetails brandSlug={filters.brandName} q={filters.q} categories={filters.categories} />
  )
}

export default Store
