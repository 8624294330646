import { MenuMobileCollapsiblesEnum } from './MenuMobile'
import { MenuMobileItemStyled } from './styles'
import { RightOutlined } from '@ant-design/icons'

interface MenuMobileItemProps {
  label: string
  slug: keyof typeof MenuMobileCollapsiblesEnum
  onSelect: (slug: keyof typeof MenuMobileCollapsiblesEnum) => void
}

export const MenuMobileItem: React.FC<MenuMobileItemProps> = ({ label, slug, onSelect }) => {
  return (
    <MenuMobileItemStyled onClick={() => onSelect(slug)}>
      <span>{label}</span> <RightOutlined />
    </MenuMobileItemStyled>
  )
}
