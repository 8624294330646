import React from 'react'
import { ContainerStyle } from './styles'

type IProps = {
  isMobile?: boolean
  children: React.ReactNode
}

const Container = (props: IProps) => {
  return <ContainerStyle>{props.children}</ContainerStyle>
}

export default Container
