import { gql } from '@apollo/client'

export const mutationRegisterBuyer = gql`
  mutation registerBuyer($input: InputRegister!) {
    register(input: $input) {
      code
      msg
      account {
        _id
        name
        lastname
        ruc
        company
        cellphone
        email
      }
    }
  }
`
