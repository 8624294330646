import { useQuery, useMutation } from '@apollo/client'

import { Platforms } from 'src/interfaces/types/app'
import {
  CompleteVariables,
  ConfirmData,
  ConfirmVariables,
  RegisterData,
  RegisterVariables,
} from 'src/interfaces/accounts'

import { queryObtainAccount } from '../queries/accounts'

import { mutationComplete, mutationConfirm, mutationRegister } from '../mutations/accounts'

type QueryParams = Parameters<typeof useQuery>

export const obtainAccount = (input: any, config?: QueryParams[1]) => {
  const { variables, context, ...restOptions } = config ?? {}
  const response = useQuery<any>(queryObtainAccount, {
    context: { clientName: Platforms.accounts, ...context },
    variables: { input, ...variables },
    ...restOptions,
  })
  return response
}

export const useRegister = () => useMutation<RegisterData, RegisterVariables>(mutationRegister)

export const useConfirm = () => useMutation<ConfirmData, ConfirmVariables>(mutationConfirm)

export const useComplete = () => useMutation<ConfirmData, CompleteVariables>(mutationComplete)
