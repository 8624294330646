import { useState } from 'react'

export const useDialog = (initial = false) => {
  const [open, setOpen] = useState(initial)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleToggle = () => setOpen((state) => !state)

  return { open, setOpen, handleOpen, handleClose, handleToggle }
}
