import { Slide } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'

import { Banner } from 'src/interfaces/structure'

import { EachSliderStyled, SliderStyled } from './styles'
import { Grid } from 'antd'

interface IProps {
  images: Banner[] | undefined
}
const { useBreakpoint } = Grid

export const Slider = (props: IProps) => {
  const { md } = useBreakpoint()

  const slides = props?.images?.map((slide) => {
    return {
      imageUrl: slide.imageUrl,
      imageMobileUrl: slide.imageMobileUrl,
      caption: '',
      customWrap: Boolean(slide?.redirectTo)
        ? (el: React.ReactNode) => (
            <a
              href={slide.redirectTo}
              target={slide.redirectTo.includes('https://') ? '_blank' : '_self'}
              rel="noreferrer"
              key={'customWrap'}
            >
              {el}
            </a>
          )
        : undefined,
    }
  })

  if (!slides?.length) return <div></div>

  return (
    <SliderStyled className="slide-container">
      <Slide
        transitionDuration={300}
        arrows={(slides?.length ?? 1) > 1}
        autoplay={(slides?.length ?? 1) > 1}
      >
        {slides?.map((imgItem, index: number) => {
          const el = (
            <EachSliderStyled key={index}>
              <img
                className="img"
                src={md ? imgItem.imageUrl : imgItem.imageMobileUrl}
                alt={imgItem.caption}
              />
            </EachSliderStyled>
          )
          return imgItem?.customWrap?.(el) ?? el
        })}
      </Slide>
    </SliderStyled>
  )
}
