import styled from 'styled-components'

import sizes from 'src/styles/theme/sizes'

export const ProductsStyled = styled.div`
  padding: 0;
  img {
    height: 140px;
  }
  .product-name {
    font-size: 12px !important;
  }
  @media screen and (min-width: ${sizes.md}) {
    img {
      height: 240px;
    }
    .product-name {
      font-size: 16px !important;
    }
  }
`
