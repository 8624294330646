import styled from 'styled-components'

import sizes from 'src/styles/theme/sizes'

export const CategoriesStyle = styled.div`
  padding: 65px 0;
  .category {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .category-name {
    overflow: hidden;
    text-overflow: ellipsis;
    /*display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;*/
    /* height: 48px; */
    font-size: 18px;
    color: #343a40;
    font-weight: 500;

    height: 48px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: ${sizes.sm}) {
    .brand {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
`
