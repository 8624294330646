import styled from 'styled-components'

export const EmptyStyled = styled.div`
  border-top: 1px solid #bfbfbf;
  padding-top: 26px;
  margin-top: 26px;
  display: grid;
  place-items: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`
