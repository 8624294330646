import styled from 'styled-components'

import { CardProps } from './index'

const shadowStyles = `
  box-shadow: 0px 10px 30px 0px #195AFF26;
`

const roundedStyles = `
  border-radius: 0.5rem;
`

export const CardStyle = styled.div<CardProps>`
  border-radius: 4px;
  padding: 10px;
  background-color: #ffffff;
  ${({ hasShadow }) => (hasShadow ? shadowStyles : '')}
  ${({ isRounded }) => (isRounded ? roundedStyles : '')}
  .product-name {
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 48px;
  }
  .icon {
    font-size: 18px;
  }
  a {
    text-decoration: none;
  }
`
