import styled from 'styled-components'

export const QPdfReaderStyled = styled.div`
  min-height: 850px;
  background-color: #dedede;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;

  @media screen {
    @media (max-width: 992px) {
      height: 250px;
    }

    @media (max-width: 720px) {
      min-height: 420px;
    }
  }
`
