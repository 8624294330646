import { SponsorsStyled } from './styles'

const Sponsors = () => {
  return (
    <SponsorsStyled>
      <h2 className="title">Somos parte de</h2>
      <div className="grid-images">
        {Array.from({ length: 8 }, (_, index) => index).map((idx: number) => (
          <img
            key={idx}
            className="image"
            src={`/images/sponsors/im${idx}.svg`}
            height="67"
            width="134"
          />
        ))}
      </div>
    </SponsorsStyled>
  )
}

export default Sponsors
