import { useMutation } from '@apollo/client'

import { mutationAddProject } from '../mutations/project'
import { Input } from '../../graphql.types'

type InputAddProject = Input<
  Partial<{
    name: string
    address: string
    addressFormatted: string
    referencial: string
    district: string
    province: string
    department: string
    mapUrl: string
    otherIsOther: string
    otherName: string
    otherLastname: string
    otherCellphone: string
    otherRUC: string
    otherCompany: string
    accountId: string
    requirementId: string
  }>
>
interface ProjectData {
  addProject: Partial<{
    _id: string
    name: string
    address: string
    addressFormatted: string
  }>
}

export const useAddProject = () => useMutation<ProjectData, InputAddProject>(mutationAddProject)
