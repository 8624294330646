import { FC } from 'react'
import { Spin } from 'antd'

import { BannerStyled } from './styles'

interface BannerProps {
  logoUrl?: string
  name?: string
  bannerUrl?: string
  loading?: boolean
}
const Banner: FC<BannerProps> = ({ logoUrl, name, bannerUrl, loading = false }) => {
  const handleError = (
    { currentTarget }: React.SyntheticEvent<HTMLImageElement, Event>,
    type: 'banner' | 'logo'
  ) => {
    currentTarget.onerror = null
    currentTarget.src = `/images/no-${type === 'banner' ? 'banner' : 'image'}.png`
    currentTarget.className = 'fallback'
  }

  return (
    <>
      <Spin spinning={loading}>
        <BannerStyled>
          <img
            className="banner-pic"
            src={bannerUrl ?? '/images/no-banner.png'}
            alt={`${name} brand banner`}
            onError={(e) => handleError(e, 'banner')}
          />
          <div className="profile-pic">
            <img
              className=""
              src={logoUrl ?? '/images/no-image.png'}
              alt={`${name} logo brand`}
              onError={(e) => handleError(e, 'logo')}
            />
          </div>
        </BannerStyled>
      </Spin>
    </>
  )
}

export default Banner
