import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Result } from 'antd'

import { Platforms } from 'src/interfaces/types/app'

import { useConfirm } from 'src/api/graphql/resolvers/accounts'

import { QLoading } from 'src/shared/components'

import { validateHash } from '../helpers/validations'
import config from 'src/config/app'

import { ButtonStyled, CardStyled, CenterStyled } from '../styles'

const Confirm = () => {
  const { accountId } = useParams()
  const [hasError, setHasError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [msg, setMsg] = useState<string>()
  const [confirm] = useConfirm()

  const confirmation = async (accountId: string) => {
    confirm({
      context: { clientName: Platforms.accounts },
      variables: { input: { accountId } },
    })
      .then((res) => {
        setMsg(res.data?.confirm.msg)
        setLoading(false)
      })
      .catch(() => {
        setHasError(true)
        setLoading(false)
      })
  }

  const pending = (
    <QLoading loading={loading}>
      <Result style={{ padding: '0' }} status="warning" title={'Confirmación pendiente'} />
    </QLoading>
  )

  const success = (
    <Result
      style={{ padding: '0' }}
      status="success"
      title={msg ?? 'Registro confirmado'}
      extra={
        <>
          <p>Bienvenido a Equip, haz click en el siguiente botón para iniciar sesión</p>
          <a href={config.platform.auth}>
            <ButtonStyled type="primary" size="large">
              Iniciar Sesión
            </ButtonStyled>
          </a>
        </>
      }
    />
  )

  const error = (
    <Result style={{ padding: '0' }} status="error" title={'Link de confirmación incorrecto'} />
  )

  useEffect(() => {
    if (accountId) {
      if (validateHash(accountId)) confirmation(accountId)
      else setHasError(true)
    }
  }, [accountId])

  return (
    <CenterStyled>
      <CardStyled>
        {loading && pending}
        {hasError && error}
        {!loading && !hasError && success}
      </CardStyled>
    </CenterStyled>
  )
}

export default Confirm
