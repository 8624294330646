import { useEffect, useState } from 'react'
import { useListFilters as lstFilters, useListCategories } from 'src/api/graphql'

interface UseListFiltersReturn {
  categories: PanelCategory[]
  brands: BrandsByCategory
}

export interface PanelCategory {
  name: string
  id: string
  subcategories: { name: string; id: string }[]
}

export type BrandsByCategory = {
  category: string
  brands: { label: string; id: string; hasStore: boolean }[]
}[]

export const useListFilters = (): UseListFiltersReturn => {
  const [categories, setCategories] = useState<PanelCategory[]>([])
  const [brands, setBrands] = useState<BrandsByCategory>([])

  const query = lstFilters({ categories: [], subcategories: [], brands: [] })
  const queryCategories = useListCategories({ page: 1, perPage: 230 }, { q: '' })

  useEffect(() => {
    if (!query.loading && query.data) {
      // Categories
      const dt = query.data?.filters ?? []

      const aux1: any[] = []
      dt.forEach((itm: any) => aux1.push(...itm.categories))
      const aux2: any = {}
      aux1.forEach((itm: any) => {
        if (aux2[itm.category]) {
          aux2[itm.category] = [
            ...aux2[itm.category],
            ...itm.subcategories.map(({ subcategory }: any) => subcategory),
          ]
        } else {
          aux2[itm.category] = itm.subcategories.map(({ subcategory }: any) => subcategory)
        }
      })

      const adaptedCategories: any[] = []
      Object.keys(aux2).forEach((category: string, index: number) => {
        const ll: string[] = [...new Set(aux2[category])] as string[]
        const tm = {
          name: category,
          subcategories: ll.map((itm: string, idx: number) => ({
            name: itm,
            id: `${index}-${idx}`,
          })),
        }
        // if (tm.subcategories.length) adaptedCategories.push(tm)
        adaptedCategories.push(tm)
      })

      setCategories(adaptedCategories)
    }
  }, [query.data, query.loading])

  useEffect(() => {
    if (!queryCategories.loading && queryCategories.data) {
      const adaptedBrands: any[] = []
      queryCategories.data.listCategories?.categories?.forEach((cat: any) => {
        const jsn = {
          category: cat.name,
          brands: cat.storeBrandsEntity?.map((ss: any) => ({
            id: ss._id,
            label: ss.name,
            hasStore: ss.hasStore,
          })),
        }
        if (jsn.brands.length > 0) {
          adaptedBrands.push(jsn)
        }
      }) ?? []
      setBrands(adaptedBrands)
    }
  }, [queryCategories.data, queryCategories.loading])

  return { categories, brands }
}
