import { gql } from '@apollo/client'

export const mutationRegister = gql`
  mutation register($input: InputRegister!) {
    register(input: $input) {
      code
      msg
      account {
        _id
        code
        avatar
        name
        lastname
        ruc
        company
        email
        cellphone
        address
        categories
        documentType
        documentNumber
        isBuyer
        isProvider
        isTerms
        isMkt
        status
        origin
        accountVid
        processing
        companyVid
        createdAt
        updatedAt
      }
    }
  }
`

export const mutationConfirm = gql`
  mutation confirm($input: InputConfirm!) {
    confirm(input: $input) {
      msg
      code
    }
  }
`

export const mutationComplete = gql`
  mutation complete($input: InputComplete!) {
    complete(input: $input) {
      msg
      code
    }
  }
`
