import { useLayoutEffect } from 'react'
import { Outlet } from 'react-router'
import { useLocation } from 'react-router-dom'

import App from 'src/layouts/App'
import { WhatsAppFloat } from './Whatsapp'

export const Root = () => {
  const { pathname } = useLocation()

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <App hasNavbar={pathname != '/register'} hasFooter={pathname != '/register'}>
      <Outlet />
      {pathname != '/register' && <WhatsAppFloat />}
    </App>
  )
}
