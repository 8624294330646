import styled from 'styled-components'

import sizes from 'src/styles/theme/sizes'

export const MenuIconStyled = styled.div`
  display: block;

  @media screen and (min-width: ${sizes.md}) {
    display: none;
  }
`

export const NavIconsWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  @media screen and (min-width: ${sizes.md}) {
    gap: 2rem;
  }
`

export const NavigationMobile = styled.ul`
  font-size: 0.875rem;
  @media screen and (min-width: ${sizes.md}) {
  }
`

export const DrawerHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  img.logo {
    height: 2rem;
  }
`
