import { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { Button, Spin } from 'antd'
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'

import { QPdfReaderStyled } from './styles'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const styles: any = {
  button: {
    marginRight: 10,
    background: '#ffbe2e',
    color: 'white',
    border: 'none',
    padding: '5px 10px',
    width: 100,
    cursor: 'pointer',
    boxShadow: '2px 2px 2px 1px #ccc',
  },
}

export const QPdfReader = (props: any) => {
  const { url } = props

  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)

  const handleBefore = () => {
    if (page > 1) setPage(page - 1)
  }

  const handleNext = () => {
    if (page < count) setPage(page + 1)
  }

  return (
    <div>
      <QPdfReaderStyled>
        <Document
          file={{ url }}
          loading={<Spin className="window-center" />}
          onLoadSuccess={({ numPages }: any) => setCount(numPages)}
        >
          {window.innerWidth < 500 ? (
            <Page height={window.innerWidth} pageNumber={page} />
          ) : (
            <Page pageNumber={page} />
          )}
        </Document>
      </QPdfReaderStyled>
      <div style={{ textAlign: 'center', paddingTop: 20, paddingBottom: 20 }}>
        <label>
          Página {page} de {count}
        </label>
        <div>
          <Button style={styles.button} onClick={handleBefore} disabled={page <= 1}>
            <CaretLeftOutlined />
          </Button>
          <Button style={styles.button} onClick={handleNext} disabled={page >= count}>
            <CaretRightOutlined />
          </Button>
        </div>
      </div>
    </div>
  )
}
