import styled from 'styled-components'

export const ProviderFiltersStyled = styled.aside`
  width: 100%;
  max-width: 14.875rem;
  .provider-filters-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ant-checkbox + span {
    width: 100% !important;
    .count {
      color: #8c8c8c;
    }
  }
`
