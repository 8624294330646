import { gql } from '@apollo/client'

export const queryListInventories = gql`
  query list($paginated: Paginated, $filter: FilterListInventory) {
    getInventories(paginated: $paginated, filter: $filter) {
      pageInfo {
        total
        currentPage
        perPage
        hasPreviousPage
        hasNextPage
      }
      inventories {
        key: _id
        product
        skuEquip
        skuParent
        sku
        unity
        stock
        currency
        price
        pvp
        igv
        vol1
        pricevol1
        vol2
        pricevol2
        brand
        categories
        subcategories
        deepcategories
        image0
        image1
        image2
        image3
        description
        variant
        color
        presentation
        type
        model
        size
        measure
        thickness
        weight
        fabricator
        material
        reference
        techsheetUrl
        warrantyUrl
        deliveryAmountToFree
        deliveryLimaPrice
        deliveryProvincePrice
        deliveryPolicy
      }
    }
  }
`

export const queryObtainInventory = gql`
  query getInventory($input: InputID) {
    getInventory: obtainInventory(input: $input) {
      key: _id
      product
      skuEquip
      skuParent
      sku
      unity
      variants {
        key: _id
        product
        skuEquip
        skuParent
        sku
        unity
        stock
        currency
        price
        pvp
        igv
        vol1
        pricevol1
        vol2
        pricevol2
        brand
        categories
        subcategories
        deepcategories
        image0
        image1
        image2
        image3
        description
        variant
        color
        presentation
        type
        model
        size
        measure
        thickness
        weight
        fabricator
        material
        reference
        techsheetUrl
        warrantyUrl
        deliveryAmountToFree
        deliveryLimaPrice
        deliveryProvincePrice
        deliveryPolicy
      }
      stock
      currency
      price
      pvp
      igv
      vol1
      pricevol1
      vol2
      pricevol2
      brand
      categories
      subcategories
      deepcategories
      image0
      image1
      image2
      image3
      description
      variant
      color
      presentation
      type
      model
      size
      measure
      thickness
      weight
      fabricator
      material
      reference
      techsheetUrl
      warrantyUrl
      deliveryAmountToFree
      deliveryLimaPrice
      deliveryProvincePrice
      deliveryPolicy
      brandEntity {
        name
        hasStore
        isActive
      }
    }
  }
`
export const queryObtainInventoryEquip = gql`
  query obtainInventoryEquip($input: InputSKU) {
    obtainInventoryEquip(input: $input) {
      key: _id
      product
      skuEquip
      skuParent
      sku
      unity
      variants {
        key: _id
        product
        skuEquip
        skuParent
        sku
        unity
        stock
        currency
        price
        pvp
        igv
        vol1
        pricevol1
        vol2
        pricevol2
        brand
        categories
        subcategories
        deepcategories
        image0
        image1
        image2
        image3
        description
        variant
        color
        presentation
        type
        model
        size
        measure
        thickness
        weight
        fabricator
        material
        reference
        techsheetUrl
        warrantyUrl
        deliveryAmountToFree
        deliveryLimaPrice
        deliveryProvincePrice
        deliveryPolicy
      }
      stock
      currency
      price
      pvp
      igv
      vol1
      pricevol1
      vol2
      pricevol2
      brand
      categories
      subcategories
      deepcategories
      image0
      image1
      image2
      image3
      description
      variant
      color
      presentation
      type
      model
      size
      measure
      thickness
      weight
      fabricator
      material
      reference
      techsheetUrl
      warrantyUrl
      deliveryAmountToFree
      deliveryLimaPrice
      deliveryProvincePrice
      deliveryPolicy
      isActive
      brandEntity {
        name
        hasStore
        isActive
      }
    }
  }
`

export const queryListBrands = gql`
  query listBrands($paginated: Paginated, $filter: FilterListBrand) {
    listBrands(paginated: $paginated, filter: $filter) {
      pageInfo {
        total
        currentPage
        perPage
        hasPreviousPage
        hasNextPage
      }
      brands {
        id: _id
        name
        imageUrl
        bannerUrl
        categories
        categoriesEntity {
          _id
          name
          subcategories {
            _id
            name
          }
        }
        hasStore
        url: logoUrl
        accountId
        updatedAt
        createdAt
      }
    }
  }
`

export const queryFindBrand = gql`
  query findBrand($params: FilterParamsBrand) {
    findBrand(params: $params) {
      id: _id
      name
      imageUrl
      bannerUrl
      bannerMobileUrl
      url: logoUrl
      hasStore
      categories
      categoriesEntity {
        _id
        name
        subcategories {
          _id
          name
        }
      }
      accountId
      updatedAt
      createdAt
    }
  }
`

export const queryListInventoriesParents = gql`
  query listParents($paginated: Paginated, $filter: FilterListInventory) {
    listInventoriesSmartEvol(paginated: $paginated, filter: $filter) {
      pageInfo {
        total
        currentPage
        perPage
        hasPreviousPage
        hasNextPage
        __typename
      }
      inventories {
        key: _id
        product
        skuEquip
        skuParent
        sku
        unity
        stock
        currency
        price
        pvp
        igv
        vol1
        pricevol1
        vol2
        pricevol2
        brand
        categories
        subcategories
        deepcategories
        image0
        image1
        image2
        image3
        description
        variant
        color
        presentation
        type
        model
        size
        measure
        thickness
        weight
        fabricator
        material
        reference
        techsheetUrl
        warrantyUrl
        deliveryAmountToFree
        deliveryLimaPrice
        deliveryProvincePrice
        deliveryPolicy
      }
    }
  }
`

export const queryECommerce = gql`
  query ecommerce($paginated: Paginated, $filter: FilterListInventory) {
    ecommerce(paginated: $paginated, filter: $filter) {
      pageInfo {
        total
        currentPage
        perPage
        hasPreviousPage
        hasNextPage
      }
      inventories {
        key: _id
        product
        skuEquip
        skuParent
        sku
        unity
        stock
        currency
        price
        pvp
        igv
        vol1
        pricevol1
        vol2
        pricevol2
        brand
        categories
        subcategories
        deepcategories
        image0
        image1
        image2
        image3
        description
        variant
        color
        presentation
        type
        model
        size
        measure
        thickness
        weight
        fabricator
        material
        reference
        techsheetUrl
        warrantyUrl
        deliveryAmountToFree
        deliveryLimaPrice
        deliveryProvincePrice
        deliveryPolicy
      }
    }
  }
`

export const queryListFavorites = gql`
  query listFavorites {
    listFavorites {
      section
      names
    }
  }
`
